.switch-account-modal {
  width: 368px !important;

  .ant-modal-content,
  .ant-modal-header {
    border-radius: 8px !important;
  }

  .ant-modal-close {
    display: none;
  }

  .licenses {
    padding: 0rem 2rem;

    max-height: 400px;
    overflow-y: auto;

    .license {
      display: flex;
      align-items: center;
      padding: 1rem;
      cursor: pointer;
      border-radius: 4px;

      &:hover {
        background-color: #effbff;
      }

      &.selected {
        background-color: #eafaff;
      }

      .thumbnail {
        width: 62px;
        height: 62px;
        border-radius: 4px;

        margin-right: 1rem;
      }

      .info * {
        font-family: Roboto;
        font-size: 14px;
      }

      .name {
        font-weight: 500;
      }

      .role {
        font-style: italic;
        font-weight: 400;
      }
    }
  }

  .open-btn {
    margin-top: 2rem;
    width: 100%;
    text-align: center;
    button {
      border-radius: 10px;
      padding: 0.5rem 1.5rem;
      background-color: #0075e1;
      color: white;
      border: none;
      outline: none;
      
    }

    button:hover {
      background-color: #2395ff;
    }

    button:active {
      background-color: #0063c0;
    }

    button:disabled {
      background-color: #a7a7a7;
      cursor: not-allowed;
    }
  }

  .footer {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
    color: #0094ff;

    img {
      margin-right: 0.5rem;
    }

    .settings {
      margin-left: auto;
      a {
        color: #0094ff !important;
      }
    }

    .support {
      margin-left: 2rem;
    }
  }
}
