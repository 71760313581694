.shared-scenes {
  // flex-basis: 100%;
  width: 78vw;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;

  &.original {
    background-color: #11202e !important;
  }
  &.black {
    background-color: black !important;
  }
}
