#lib-sidebar {
  & {
    height: 100%;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    overflow: auto;
    padding: 0rem;
    // flex: 1;
    width: 25vw;
    outline: none;

    // @media (max-width: 400px) {
    //   width: 150px;
    // }
  }

  &.focused {
    // outline: none;
    // background-color: #05192c;
  }

  ul {
    padding: 0;
    margin-bottom: 0px;
    list-style-type: none;
  }

  li {
    padding: 2vw 2vw;
    font-size: 2vw;
    word-wrap: break-word;
    outline: none;

    display: flex;
    align-items: center;

    .badge {
      display: flex;
      padding: 0.75vw 1vw;
      margin-left: auto;
      border-radius: 50%;
      color: #0a141d !important;
      background-color: rgba(255, 255, 255, 0.3);
    }

    &:hover {
      cursor: pointer;
      background-color: rgba(255, 255, 255, 0.1);
    }

    &:focus {
      background-color: #1a77e849;
      background-color: rgba(255, 255, 255, 0.6);
    }

    &.selected {
      background-color: #1a76e8;
      .badge {
        color: #1a76e8 !important;
        background-color: rgba(255, 255, 255, 0.7);
      }
    }

    .category {
      margin-right: auto;
    }

    img {
      display: inline;
    }

    .caret {
      margin-left: 0.5vw;
    }

    &.separator {
      height: 0px !important;
      padding: 0px;
      border-top: 1px solid rgba($color: white, $alpha: 0.2);
    }
  }

  .sub-menu {
    // margin-left: 1.5rem;
    padding: 3vw;
    padding-left: 3.5vw;
    padding-right: 1.5vw;
    font-size: 1.75vw;
    word-wrap: break-word;
    outline: none;

    .sub-menu-name {
      margin-right: auto;
    }
  }

  /* -------------------------------------------------- Scroll-bar */

  &::-webkit-scrollbar {
    width: 1vw;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(104, 104, 104, 0.7);
    box-shadow: inset 0 0 6px rgba(104, 104, 104, 0.7);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(255, 251, 251, 0.05);
    -webkit-box-shadow: inset 0 0 6px rgba(90, 90, 90, 0.7);
    box-shadow: inset 0 0 6px rgba(90, 90, 90, 0.7);
  }
}
