.ant-upload {
  width: 120px;
  height: 120px;
  background-color: white;
  border: 1px solid grey;
  border: none;

  .img-upload-icon {
    position: relative;
    width: 100%;
    height: 100%;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    border: 1px solid rgba($color: #000000, $alpha: 0.2);

    div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 32px;
      color: black;
    }
  }
}

.ant-upload.ant-upload-select.ant-upload-select-picture-card {
  display: inherit;
  /* width: auto; */
  /* height: auto; */
  width: 120px;
  height: 1200px;
  /* margin: 5px; */
}

/* .img-crop-child {
  font-size: 50px;
  color: black;
}

.ant-upload-list-item.ant-upload-list-item-done.ant-upload-list-item-list-type-picture-card,
.ant-upload-list-item.ant-upload-list-item-done.ant-upload-list-item-list-type-picture-card
  *,
.ant-upload-list-picture-card-container {
  display: none;
  height: 0px !important;
  width: 0px !important; 
}
*/
