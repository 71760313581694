.update-notification-modal {
  padding: 1vw;
  width: 40vw !important;

  .ant-modal-body {
    padding: 2vw;
  }

  h1 {
    font-size: 2vw;
    margin-bottom: 1vw;
  }

  p {
    color: grey;
    margin-bottom: 0.5vw;
    font-size: 1.2vw;
  }

  footer {
    margin-top: 2vw;
    button {
      margin-right: 1vw;
      padding: 0.5vw 1vw;
      border: solid 0.1vw;
      border-radius: 0.2vw;
      font-size: 1.2vw;
    }

    .later-btn {
    }

    .update-btn {
      outline: none;
      width: 8vw;
      background-color: #2563d2;
      border-radius: 0.8vw;
      color: white;
    }
  }
}
