.add-credit-modal {
  .ant-input-number {
    margin-bottom: 1rem;
    width: 100%;
    display: block;
  }

  .pay-btn {
    width: 100%;
  }
}
