#mask-btn {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  padding: 0;
  margin: 0;
}

#mask-btn:focus {
  border: none;
  box-shadow: none;
}

#mask-btn:active {
  border: none;
  box-shadow: none;
}

.likes-container {
  position: relative;
  height: 0px;
}

.likes {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 8px;
  /*line-height: 9px; */
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.4);

  padding-left: 4px;
  margin-top: 4px;

  cursor: pointer;
}

.likes-content {
  max-height: 200px;
  overflow: auto;
}

.like-items {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.like-background {
  display: flex;
  height: 20px;
  width: 20px;
  /* margin-left: 2px; */
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  padding: 10px;
  background-color: rgb(59, 89, 152);
}

.like-hand {
  color: white;
}

.likes-number {
  color: white;
  /* margin: auto; */
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.likes {
  background: #252525;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 40px;
  padding: 1px;
}

.likes-position {
  position: absolute;
  right: -20px;
  bottom: -12px;
}
