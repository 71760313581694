#library {
  height: 100vh;
  margin: 0px;
  background-color: rgba(1, 2, 2, 0.4);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-content: stretch;
}

/* -------------------------------------------------- Main */

#lib-main {
  overflow: auto;

  flex-grow: 1;

  display: flex;
  align-content: start;
  flex-wrap: wrap;
  align-items: stretch;

  .items-container {
    height: 100%;
    width: 100%;

    overflow: auto;
    flex: 3;
    flex-grow: 1;

    outline: none;

    /* -------------------------------------------------- Scroll-bar */

    &::-webkit-scrollbar {
      width: 1vw;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      box-shadow: inset 0 0 6px rgba(104, 104, 104, 0.7);
      -webkit-box-shadow: inset 0 0 6px rgba(104, 104, 104, 0.7);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: rgba(255, 251, 251, 0.05);
      box-shadow: inset 0 0 6px rgba(104, 104, 104, 0.7);
      -webkit-box-shadow: inset 0 0 6px rgba(104, 104, 104, 0.7);
    }
  }
}
