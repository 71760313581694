.open-scene-modal {
  .ant-modal-content {
    border-radius: 10px;
  }

  .ant-modal-close {
    display: none;
  }

  .ant-modal-body {
    padding: 0;
  }

  .hero {
    position: relative;

    img {
      border-radius: 10px 10px 0px 0px;
      object-fit: cover;

      width: 100%;
      height: 200px;
    }

    h1 {
      margin: 0px;
      color: white;
      font-size: 24px;
      font-weight: 700;
      text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .loading {
    margin: 1rem auto;
    padding-bottom: 0rem;
    display: flex;
    // align-items: center;
    // text-align: center;

    .loader {
      margin-left: auto;
      margin-right: 1rem;
    }

    p {
      margin-right: auto;
      color: #949494;
    }
  }

  .footer {
    margin: 1rem auto;
    padding-bottom: 1rem;
    text-align: center;
    button {
      outline: none;
      border: none;
      border-radius: 5px;
      color: white;
      padding: 0.25rem 0rem;
    }

    .cancel {
      background-color: #b4b4b4;
      margin-right: 1rem;
      margin-left: auto;
      width: 110px;

      &:hover {
        background-color: #cfcfcf;
      }

      &:active {
        background-color: #a1a1a1;
      }
    }

    .enter {
      width: 110px;
      background-color: #1a76e8;

      &:hover {
        background-color: #2d8cff;
      }

      &:active {
        background-color: #005ac7;
      }

      &:disabled {
        background-color: grey;
        cursor: not-allowed;
      }
    }
  }
}
