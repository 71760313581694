.modal-backdrop {
  transition: opacity 0s linear !important;
}

.fullscreen-image-modal {
  height: 80vh !important;
  width: 80vw !important;
  padding: 0px;
  padding-left: 0px !important;
  background-color: rgba($color: #000000, $alpha: 0.4) !important;
  transition: none !important;

  * {
    background-color: rgba($color: #000000, $alpha: 0.4) !important;
  }

  .modal-dialog {
    margin: 0;
    width: 100%;
    height: 100%;
    max-width: none !important;
    transition: none !important;
  }

  .modal-content {
    width: 100%;
    height: 100%;
    border: none;
  }

  .modal-body {
    padding: 0px;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
