.blueprint-tree {
  height: 100%;
}

.blueprint-tree ul {
  list-style-type: none;
  list-style: none;
  padding: 0;
}

.blueprint-tree .eye-icon {
  cursor: pointer;
}

.blueprint-tree-title {
  font-family: 'Roboto';
  font-weight: 900;
  font-size: 10px;

  margin-top: 2rem;
  margin-bottom: 0.5rem;
  padding-left: 1.5rem;
  padding-right: 1rem;

  display: flex;

  color: rgba(255, 255, 255, 0.6);
}

.blueprint-tree-title .title-icon {
  margin-right: 0.75rem;
}

.blueprint-tree-elem {
  font-family: 'Roboto';
  font-size: 12px;

  /* border: 1px solid white; */

  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 2.5rem;
  padding-right: 1rem;
  padding-bottom: 2px;

  transition: 400ms;

  display: flex;
}

.blueprint-tree-elem .name {
  border-radius: 10px;
  padding: 0.25rem 0.75rem;
  border: 1px solid #09141d;
  text-transform: capitalize ;
}

.blueprint-tree-elem.hidden *,
.blueprint-tree-title.hidden * {
  color: #474f56 !important;
}

.blueprint-tree-elem.selected .name {
  background-color: #0094ff;
}

.blueprint-tree-elem.hovered .name {
  border: 1px solid #0193fb;
}

.blueprint-tree-elem:hover {
  cursor: pointer;
}

.blueprint-tree-elem .eye-icon,
.blueprint-tree-title .eye-icon {
  margin-left: auto;
  color: white;
  opacity: 0;
  transition: 500ms;
}

.blueprint-tree-elem.hidden .eye-icon,
.blueprint-tree-title.hidden .eye-icon,
.blueprint-tree-elem:hover .eye-icon,
.blueprint-tree-title:hover .eye-icon {
  opacity: 1;
}

.blueprint-tree-container::-webkit-scrollbar {
  width: 12px;
}

.blueprint-tree-container::-webkit-scrollbar-track {
  border-radius: 10px;
}

.blueprint-tree-container::-webkit-scrollbar-thumb {
  border-radius: 10px;

  background-color: rgba(255, 251, 251, 0.05);
  -webkit-box-shadow: inset 0 0 6px rgba(90, 90, 90, 0.7);
}
