.list-view.panorama {
  #items-grid {
    display: flex;
    flex-direction: column;
    padding: 0px;
    margin: 0px;
  }

  .carousel-control-prev,
  .carousel-control-next {
    opacity: 0;
  }

  #items-grid .item {
    position: relative;
    border-bottom: 1px rgba(255, 251, 251, 0.15) solid;
    margin-bottom: 0px;
    width: 100%;
    // height: 180px;
    height: 40vw;
    padding: 0;

    // /* Wearables, small mobile devices (less than 320px)*/
    // @media (max-width: 319px) {
    //   & {
    //     height: 145px;
    //   }
    // }

    // /* Mobile devices (portrait phones, 320px and up) */
    // @media (min-width: 320px) {
    //   & {
    //     height: 145px;
    //   }
    // }

    // @media (min-width: 480px) {
    //   & {
    //     height: 200px;
    //   }
    // }

    // @media (min-width: 600px) {
    //   & {
    //     height: 250px;
    //   }
    // }
  }

  #items-grid .item:hover {
    background-color: rgba(255, 251, 251, 0.1);
    .carousel-control-prev,
    .carousel-control-next {
      opacity: 0.5;
    }

    .carousel-control-prev:hover,
    .carousel-control-next:hover {
      opacity: 1;
    }
  }

  #items-grid .item .item-card {
    width: 100%;
    height: 100%;
    border-radius: 0px;

    .carousel-container,
    .carousel,
    .carousel-inner {
      height: 100%;
    }

    .carousel-item {
      // height: 100%;
      height: 50vw;
      // height: 180px;
    }

    .img-container,
    .carousel-img {
      object-fit: fill;
      // height: 340px !important;
      width: 100%;
      // height: 180px;
      height: 40vw;

      -webkit-background-size: fill;
      -moz-background-size: fill;
      -o-background-size: fill;
      background-size: fill;
      // margin-top: -35px;
    }
  }

  .item-top-row {
    position: absolute;
    display: none;
  }

  .item-bottom-row {
    position: absolute;
    z-index: 100;
    img {
      width: 25px;
    }
  }

  #items-grid .item .name {
    flex: 1;
    word-break: break-all;
    position: absolute;
    z-index: 1;
    bottom: 0px;
    left: 10px;
    h4 {
      font-size: 25px;
      font-weight: bold;
    }
  }

  #items-grid>.loader{
    margin-top: 10vw;
  }
}
