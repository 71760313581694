#blueprint {
  height: 100%;
  /* overflow-y: auto; */
  overflow: hidden;
  flex: 7;
}

.blueprint {
  width: 100%;
  height: 100%;
  background-color: #09141d;
}

.blueprint-tree-container {
  overflow: auto !important;
  height: 100% !important;
}

.blueprint-tree-container * {
  color: white;
}

.blueprint .info {
  height: 100%;
  background-color: #09141d;
  overflow: auto;
}

.ant-slider {
  width: 100%;
}

.blueprint-editor-empty {
  width: 100%;
  height: 100%;
}

.blueprint-editor-empty * {
  color: rgba(255, 255, 255, 0.4);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.canvasArea {
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  position: relative;
  left: 30px;
  top: 0px;
}

.react-ruler-wrapper {
  width: 100%;
  margin: 0px 32px;
}

.rulerRow {
  background-color: #09141d;
  height: 30px;
  position: absolute;
  top: 0;
  left: 30px;
  z-index: 10;
  /* width: 800px; */
  /* width: calc(100% - 30px); */
}

.rulerCol {
  width: 30px;
  height: calc(100% - 30px);
  position: absolute;
  z-index: 10;
  border-left: 1px solid grey;
}

.box {
  position: relative;
  width: 30px;
  height: 30px;
  background: #09141d;
  box-sizing: border-box;
  z-index: 21;
  border-left: 1px solid grey;
}

.box::before {
  width: 1px;
  height: 100%;
  left: 100%;
}

.box::after {
  height: 1px;
  width: 100%;
  top: 100%;
}

.box::before,
.box::after {
  position: absolute;
  content: '';
  background: #777;
}

.blueprintSlider {
  background-color: transparent;
}

.react-ruler-wrapper .ruler-drag .ruler-point .point {
  /* background-color: rgb(142, 143, 141) !important; */
}

.react-ruler-wrapper .ruler-drag .ruler-point .point:after {
  /* border-color: rgb(142, 143, 141) !important; */
}

.blueprint .info::-webkit-scrollbar {
  width: 12px;
}

.blueprint .info::-webkit-scrollbar-track {
  border-radius: 10px;
}

.blueprint .info::-webkit-scrollbar-thumb {
  border-radius: 10px;

  background-color: rgba(255, 251, 251, 0.05);
  -webkit-box-shadow: inset 0 0 6px rgba(90, 90, 90, 0.7);
  box-shadow: inset 0 0 6px rgba(90, 90, 90, 0.7);
}
