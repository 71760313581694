.scene-switcher {
  background-color: #0a141d;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  color: white;
  overflow: hidden;

  .title {
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: center;
    font-weight: bold;
    font-size: 26px;
  }

  .scenes {
    overflow: auto;
    // TODO Change
    // height: 800px;

    .scene {
      padding-left: 2rem;
      padding-right: 2rem;
      cursor: pointer;

      

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
      .content {
        padding-top: 1rem;
        padding-bottom: 1rem;
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      }
    }

    img {
      width: 150px;
      height: 60px;
      margin-right: 2rem;
    }

    .index {
      margin-right: 2rem;
    }
  }
}
