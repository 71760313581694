.comments-panel {
  height: 100%;
}

.comments-panel .title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  text-align: center;
  color: #ffffff;
  opacity: 0.6;
}

.comments-panel .comments-header {
  /* height: 8vh; */
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: clip;
  /* padding-top: 5px; */
  /* border-bottom: 2px solid rgb(42, 42, 42); */
}

.comments-panel .comments-section {
  height: 100% !important;
  max-height: calc(100vh - 122px);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px;
  margin-top: 10px;
  width: 100%;
}

.comments-panel .comments-reply {
  /* height: 5vh; */
  width: 100%;
  text-align: center;
}

.comments-panel .comments-reply input {
  width: 100%;
  background-color: rgba(255, 251, 251, 0.05);
}

.comments-panel .comments-section::-webkit-scrollbar {
  width: 12px;
}

.comments-panel .comments-section::-webkit-scrollbar-track {
  border-radius: 10px;
}

.comments-panel .comments-section::-webkit-scrollbar-thumb {
  border-radius: 10px;

  background-color: rgba(255, 251, 251, 0.05);
  -webkit-box-shadow: inset 0 0 6px rgba(90, 90, 90, 0.7);
}

.sceneComments-header {
  font-size: 14px;
  background: #132434;
  border-radius: 20px;
  padding: 5px 25px;
  margin-bottom: 5px;
}

.referenceText {
  margin: 15px 0 0 0;
  position: relative;
  top: 8px;
  left: 5px;
  font-size: 9px;
  line-height: 12px;
  font-weight: 400;
}

.referenceText a {
  color: rgb(27, 149, 224) !important;
}

.referenceLine {
  margin: 15px 7px 0 0;
  border: 1px solid rgba(255, 255, 255, 0.4);
}

@media (max-width: 576px) {
  .comments-panel .comments-reply {
    /* height: 5vh; */
    padding-bottom: 10px;
  }
}
