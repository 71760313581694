.dots {
    color: rgba(255, 255, 255, 0.4) !important;
    cursor: pointer !important;
}

.dots:hover {
    color: rgba(255, 255, 255, 1) !important;
}

.ant-popconfirm{
    z-index: 5000 !important;
}