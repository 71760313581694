#lib-header {
  background-color: #11202e;
  padding: 2vw;

  display: flex;
  justify-content: space-between;
  align-items: center;

  flex-shrink: 0;

  & > div {
    height: 100%;
    display: flex;
    align-items: center;

    & > p {
      margin: 0px;
    }
  }
}

#lib-back p {
  font-size: 2.5vw;
  color: #a0a6ab;
}

#lib-back p:hover {
  cursor: pointer;
  color: white;
}

#lib-title {
  text-align: center;
  margin-left: auto;
  p {
    font-size: 3vw;
  }
}

#lib-header .cloud-icon {
  position: relative;
  margin-left: auto;
  margin-right: 3vw;

  cursor: pointer;

  img {
    width: 3.5vw;
    opacity: 0.6;
  }

  &.active img {
    opacity: 1;
  }

  .badge {
    display: none;
  }

  &.active .badge {
    position: absolute;
    display: inline;
    top: 1vw;
    right: -1vw;

    font-size: 1.25vw;

    padding: 0.5vw 0.75vw;

    background-color: #ff636b;
    border-radius: 50%;
  }
}

#lib-search {
  // margin-left: auto;
  margin-right: 2vw;
  text-align: right;
  opacity: 0.5;
  width: 3vw;

  img {
    width: 3vw;
  }
}

#lib-search:hover {
  opacity: 1;
  cursor: pointer;
}

#view-options {
  display: flex;
  text-align: center;
}

#view-options .view-icon {
  padding: 0rem 1.5vw;
  opacity: 0.2;

  svg {
    width: 3vw;
  }
}

#view-options .view-icon.selected {
  opacity: 0.9;
}

#view-options .view-icon:hover {
  cursor: pointer;
  opacity: 0.7;
}

#view-options .view-icon .view-name {
  font-size: 2vw;
}

// /* Wearables, small mobile devices (less than 320px)*/
// @media (max-width: 339px) {
//   #lib-back p {
//     font-size: 10px;
//   }

//   #lib-header {
//     padding: 1rem 0.5rem 0.5rem 0.5rem;
//   }

//   #lib-title {
//     font-size: 14px;
//   }
// }

// /* Mobile devices (portrait phones, 320px and up) */
// @media (min-width: 340px) {
//   #lib-header {
//     padding: 1rem 1rem 0.5rem 1rem;
//   }
// }

// /* Small devices (landscape phones, 576px and up) */
// @media (min-width: 576px) {
//   #lib-header {
//     padding: 1rem 3rem 0.5rem 3rem;
//   }
// }
