.gallery-overlay {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba($color: #000000, $alpha: 0.5);

  span {
    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    font-size: 22px;
    font-weight: bold;
  }
}

#item-gallery #gallery-header {
  & {
    flex-shrink: 0;
    display: flex;
    background-color: #11202e;
    justify-content: space-between;
    align-items: baseline;
    padding: 1rem 1rem 0rem 1rem;
  }

  #gallery-back p {
    font-size: 14px;
    color: #a0a6ab;
  }

  #gallery-back p:hover {
    cursor: pointer;
    color: white;
  }

  #gallery-title {
    text-align: center;
    margin-left: auto;
    font-size: 18px;
    margin-right: auto;
  }

  input[type='file'] {
    display: none;
  }

  .custom-file-upload {
    margin-left: auto;
    text-align: right;
    border-radius: 4px;
    background-color: #2dbdeb;
    border: none;
    cursor: pointer;
  }

  /* Wearables, small mobile devices (less than 320px)*/
  @media (max-width: 339px) {
    #gallery-back p {
      font-size: 10px;
    }

    & {
      padding: 1rem 0.5rem 0.5rem 0.5rem;
    }

    #gallery-title {
      font-size: 14px;
    }

    .custom-file-upload {
      font-size: 12px;
      margin-right: 0.5rem;
      padding: 0.1rem 0.75rem;
    }
  }

  /* Mobile devices (portrait phones, 320px and up) */
  @media (min-width: 340px) {
    & {
      padding: 1rem 1rem 0.5rem 1rem;
    }

    .custom-file-upload {
      font-size: 12px;
      margin-right: 0.5rem;
      padding: 0.1rem 1.5rem;
    }
  }

  /* Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) {
    & {
      padding: 1rem 3rem 0.5rem 3rem;
    }

    .custom-file-upload {
      font-size: 14px;
      margin-right: 1rem;
      padding: 0.1rem 2rem;
    }
  }

  /* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
  @media (min-width: 768px) {
  }

  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    .custom-file-upload {
      font-size: 14px;
      margin-right: 1rem;
      padding: 0.3rem 2rem;
    }
  }

  /* Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
  }
}
