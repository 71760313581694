.download-modal {
  .download-header {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    h1 {
      font-size: 16px;
    }

    img {
      width: 20px;
      margin-right: 1rem;
    }
  }

  .download-body {
    font-size: 12px;
    color: #606060;
  }

  .download-subcategory {
    font-size: 12px;
    color: #606060;
    font-weight: bold;
  }

  .download-footer {
    display: flex;

    .download-cancel {
      margin-left: auto;
      margin-right: 0.5rem;

      button {
        background-color: white;
        border: 1px solid #808080;
        color: #808080;
        border-radius: 5px;

        padding: 0.25rem 1.25rem;
      }
    }

    .download-confirm {
      button {
        background: #1a76e8;
        border: 1px solid #1a76e8;
        color: white;
        border: none;
        border-radius: 5px;
        font-weight: bold;
        padding: 0.25rem 1.25rem;
      }
    }
  }
}
