.personal-share-modal {
  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-close-x {
    display: none;
  }

  .ant-tabs-nav-wrap {
    padding-left: 1rem;
  }
}
