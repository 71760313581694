#items-grid {
  & {
    // height: 100%;
    width: 100%;
    overflow: auto;
    flex: 3;
    flex-grow: 1;

    display: grid;
    grid-template-columns: 25vw 25vw 25vw;
    column-gap: 3vw;
    outline: none;
    // display: flex;
    // flex-direction: column;
    // align-items: stretch;

    // position: relative;

    // display: flex;
    // flex-wrap: wrap;
    // align-content: start;
    // outline: none;
    // overflow: auto;
  }

  &.downloading {
    overflow: hidden;
  }

  &.downloading .item {
    opacity: 0.3;
  }

  .items-grid-progress-bar {
    width: 100%;
    height: 100%;
    // background-color: rgba(255, 255, 255, 0.25);
    position: absolute;
    z-index: 100;
    // top: 0%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    // -ms-transform: translate(-50%, -50%);
  }

  .items-grid-progress-bar > div {
    position: relative;
    top: 40%;
    text-align: center;
    transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);

    padding: 5rem;
  }

  .progress {
    background-color: rgba(255, 251, 251, 0);
    border: 1px solid rgba(255, 251, 251, 1);
    border-radius: 50px;
  }

  .progress-bar {
    background-color: rgba(255, 251, 251, 1) !important;
  }

  &.focused {
    // background-color: #05192c;
  }

  #empty-items-grid {
    position: relative;
    grid-column: 1/-1;
    height: fit-content;
    width: fit-content;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: grey;
    font-size: 3vw;
  }

  .download-bar {
    width: 100%;
    flex-shrink: 0;
    background-color: rgba(255, 255, 255, 0.125);
    padding-top: 0.75rem;
    margin-bottom: 0.5rem;

    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    text-align: center;
  }

  .download-bar p {
    margin-left: auto;
    margin-right: 1rem;
  }

  .download-bar button {
    margin-right: auto;
    background-color: #2dbdeb;
    border-radius: 5px;
    padding: 0.25rem 1.5rem;
  }

  @media (max-width: 489px) {
    .download-bar p {
      margin-right: auto;
      margin-left: auto;
    }

    .download-bar button {
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 1rem;
    }
  }

  .item {
    outline: none;
    // height: 35vw;

    .img-container {
      height: 25vw;
    }
  }

  .item.transparent * {
    opacity: 0.7 !important;
  }

  .item.focused {
    // background-color: rgba(255, 255, 255, 0.25);
    // border-radius: 10px;
  }
}
