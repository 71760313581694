.screen-top-icon-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  /* padding-right: 5rem; */
  margin-left: auto;
}
.screen-top-profile-icon-container {
  /* flex-basis: 70%; */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  cursor: pointer;
}

.screen-top-profile-icon-container .new-member {
  width: 1vw;
  margin-right: 1vw;
  opacity: 0.7;
  /*  */
}

.screen-top-profile-icon-container .new-member:hover {
  opacity: 1;
}

.screen-top-icon {
  /* flex-basis: 30%; */
  margin-right: 0.4vw;
}
.screen-top-icon-number-container {
  /* flex-basis: 20%; */
  background: #f79649;
  cursor: pointer;
}

.screen-top-icon-element {
  /* width: 35px;
  height: 35px; */
  width: 1.86vw;
  height: 1.86vw;
  box-shadow: 0 1vw 2vw 0 rgba(0, 0, 0, 0.2);
  /*  */
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.screen-top-icon-element:hover {
  box-shadow: 0 1vw 2vw 0 rgba(0, 0, 0, 0.8);
}

.screen-top-icon-number {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  /* font-size: 22px; */
  text-align: center;
  color: #ffffff;
  /* line-height: 55px; */
}
.screen-top-icon-plus-container {
  flex-basis: 20%;
  margin-left: 1vw;
  cursor: pointer;
}
.screen-top-icon-plus {
  font-size: 30px;
  font-weight: normal;
  color: #2d95bf !important;
}

@media (min-width: 768px) and (max-width: 992px), (max-width: 576px) {
  .screen-top-icon-container {
    padding-right: 0rem;
  }
}
