.backlot-downloads {
  & {
    background-color: #0a141d;
    color: white;
    height: 100vh;
    padding: 10vw;
    overflow: auto;
  }

  .empty-downloads {
    opacity: 0.6;
    text-align: center;
    font-size: 4vw;

    padding-top: 5vw;
    margin-top: 5vw;
  }

  .title {
    color: white;
    font-size: 5vw;
    font-weight: bold;
    padding-bottom: 5vw;
    margin-bottom: 5vw;

    border-bottom: 1px solid rgba($color: #ffff, $alpha: 0.15);
  }
  .tab {
    color: white;
    font-weight: bold;
  }
  .ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
    padding: 3vw 0;
    font-size: 3vw;
  }
  .ant-tabs-top > .ant-tabs-nav,
  .ant-tabs-bottom > .ant-tabs-nav,
  .ant-tabs-top > div > .ant-tabs-nav,
  .ant-tabs-bottom > div > .ant-tabs-nav {
    margin: 0px;
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  li {
    padding: 6vw;
    border-bottom: 1px solid rgba($color: #ffff, $alpha: 0.15);
  }

  .download-item {
    display: flex;
  }
  .download-item:hover {
    background-color: #1c2224;
    cursor: pointer;
  }
  .download-item:active {
    background-color: transparent;
    cursor: pointer;
  }
  .icon {
    margin-right: 4vw;
    img {
      width: 8vw;
      height: 8vw;
    }
  }

  .details {
    margin-right: 4vw;
    flex-grow: 1;
    padding-top: 1vw;

    .name {
      display: flex;
      font-size: 5vw;
      font-weight: bold;
      line-height: 5vw;

      svg {
        width: 5vw;
        margin-left: 3vw;
      }
    }

    .type {
      font-size: 3vw;
      font-weight: normal;
      opacity: 0.6;
    }

    > .progress-bar {
      margin: 2vw 0;
      border-radius: 5px;

      .progress-bar {
        background-color: #5bc2d9;
      }

      &,
      * {
        height: 1.5vw;
      }
    }

    .info {
      display: flex;
    }

    .percentage {
      font-size: 2vw;
    }

    .downloaded {
      margin-left: auto;
      font-size: 3vw;
      font-weight: 300;
      opacity: 0.8;
    }
  }

  .options {
    display: flex;
    align-self: center;
    padding-top: 7vw;

    .abort {
      margin-left: 2vw;
      margin-right: 2vw;
    }

    svg {
      fill-opacity: 0.6;
    }

    svg:hover {
      fill-opacity: 1;
      cursor: pointer;
    }
  }

  &::-webkit-scrollbar {
    width: 2vw;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: rgba(255, 251, 251, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;

    background-color: rgba(255, 251, 251, 0.2);
    -webkit-box-shadow: inset 0 0 6px rgba(90, 90, 90, 0.7);
    box-shadow: inset 0 0 6px rgba(90, 90, 90, 0.7);
  }
}
