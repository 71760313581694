.sidebar {
  width: 22vw;
  background-color: #132434;
  display: flex;
  flex-direction: row;
  height: 100%;
}

.widebar {
  //   width: 100%;
  width: 22vw;
  //   width: 22%;
  // background-color: #0d1a26;
  display: flex;
  flex-direction: row;
  height: 100%;

  border-right: 0.1vw solid #444444;

  &.original {
    background-color: #0d1a26 !important;
  }
  &.black {
    background-color: black !important;
  }
  // width: 270px;
  // height: 100%;
  // padding: 0 1rem 0 2.5rem;
  // order: 2;
  // display: flex;
  // flex-direction: column;
  // justify-content: flex-start;
  // overflow: scroll;
  // -ms-overflow-style: none;
  // scrollbar-width: none;
}

.widebar-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;

  .logo {
    width: 4.3vw;
    margin-left: 3.1vw;
    margin-top: 2vw;
  }
}
.widebar-container-title {
  margin-left: 3.1vw;
  margin-top: 1.25vw;
  margin-bottom: 1vw;
  //   margin-bottom: 10px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: start;

  .widebar-container-title-content {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 1.8vw;
    // line-height: 42px;
    color: #ffffff;
  }
}

.widebar-container-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  overflow-y: auto;
  margin-bottom: 1vw;
  /* height: 700px; */

  &::-webkit-scrollbar {
    width: 0.35vw;
  }

  &::-webkit-scrollbar-track {
    border-radius: 4vw;
    background-color: rgba(255, 251, 251, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4vw;

    background-color: rgba(255, 251, 251, 0.2);
    -webkit-box-shadow: inset 0 0 0.5vw rgba(90, 90, 90, 0.7);
    box-shadow: inset 0 0 0.5vw rgba(90, 90, 90, 0.7);
  }
}
.widebar-container-content-box-close {
  padding: 0.4vw 0vw 0.4vw 3.1vw;
  cursor: pointer;

  display: flex;
  // flex-direction: row;
  align-items: center;
  // justify-content: center;
  //

  &:hover {
    background-color: rgba(255, 255, 255, 0.05);
  }

  img {
    opacity: 0.6;
    width: 1.1vw;
  }

  .title {
    // display: inline;
    padding-left: 1vw;
    // flex: 2;
    // display: flex;
    flex-direction: row;
    font-family: Roboto;
    font-style: normal;
    //   font-weight: normal;
    //   font-size: 14px;
    font-size: 0.9vw;
    //   line-height: 14px;
    //   cursor: pointer;
    // padding-left: 5%;
    color: #9ea3a8;
  }
}
.widebar-container-content-box-open {
  margin-top: 0.5vw;
  display: flex;
  /* flex-basis: 30%; */
  /* max-height: 210px; */
  // max-height: 160px;
  flex-direction: column;
  justify-content: flex-start;
  //   margin-top: 10px;
  /* padding: 0 1rem; */
  // overflow: auto;
  // -ms-overflow-style: none;
  // scrollbar-width: none;
}

.widebar-container-content-box-open-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  height: 1.5vw;
  padding-left: 5.3vw;
  // padding: 3%;
  // margin-left: 15%;
  //

  opacity: 0.6;

  &:hover {
    background-color: rgba(255, 255, 255, 0.05);
    // box-shadow: 0 12px 20px 0 rgba(0, 0, 0, 0.8);
  }

  &.selected {
    background-color: rgba(255, 255, 255, 0.1);
    opacity: 0.8;
  }

  .title {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    // margin-right: 2%;
    font-size: 0.8vw;
    // line-height: 18px;
    color: #ffffff;

    // opacity: 0.6;
  }
}

.widebar-container-content-box-open-icon {
  margin-right: 3.1vw;
  width: 0.4vw;
}

.widebar-container-content-box-open-form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding-left: 5.3vw;
  height: 3vw;

  font-size: 0.8vw;
  color: #ffffff;

  div {
    cursor: pointer;
    opacity: 0.4;
  }

  div:hover {
    opacity: 1;
  }
  // padding-top: 10px;
  // padding-left: 3%;
  // margin-left: 15%;
}

// .widebar-container-content-box-open-text {
//   cursor: pointer;
//   background: #132434;
//   font-family: Roboto;
//   font-style: normal;
//   font-weight: normal;
//   font-size: 14px;
//   line-height: 12px;
//   /* width: 60%; */
//   border: none;
//   outline: none;
//   box-shadow: none;
//   resize: none;
//   color: rgba(255, 255, 255, 0.4);
//   padding-top: 3%;
// }

.widebar-container .settings-drop {
  display: flex;
  align-items: center;
  margin-bottom: 1.5vw;
  margin-left: 3.1vw;

  color: white;
  font-size: 0.9vw;
  font-weight: bold;

  cursor: pointer;

  .arrow {
    width: 0.6vw;
    margin-left: 0.6vw;
  }

  .gear-icon {
    width: 1vw;
    margin-left: auto;
    margin-right: 3.1vw;
  }
}

.settings-drop-menu {
  background: black;
  border-radius: 0.1vw;
  color: white;

  width: 100px !important;
  min-width: 200px !important;

  .drop-row {
    padding: 0.5rem 1rem;
    cursor: pointer;

    //
    border-bottom: 1px solid transparent;

    &:not(:last-child) {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    &:hover {
      background: #366794;
      border-bottom: 1px solid transparent;
    }
  }
}

.widebar-container-content-box-open::-webkit-scrollbar {
  width: 12px;
}

.widebar-container-content-box-open::-webkit-scrollbar-track {
  border-radius: 10px;
}

.widebar-container-content-box-open::-webkit-scrollbar-thumb {
  border-radius: 10px;

  background-color: rgba(255, 251, 251, 0.05);
  -webkit-box-shadow: inset 0 0 6px rgba(90, 90, 90, 0.7);
  box-shadow: inset 0 0 6px rgba(90, 90, 90, 0.7);
}

.logout-btn:hover {
  color: white;
}

// @media (max-width: 992px) and (min-width: 768px), (max-width: 576px) {
//   .widebar {
//     // width: 200px;
//     // padding-left: 2rem;
//     /* padding-left: 1rem; */
//   }
// }
