.create-scene-btn {
  position: relative;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 0.5vw;
  border: 0.1vw solid rgba(255, 255, 255, 0.3);
  cursor: pointer;
  color: white;

  display: flex;

  &:hover {
    // border-color: white;
    &:before {
      content: ' ';
      position: absolute;
      z-index: 10;
      top: -0.1vw;
      left: 0vw;
      right: -0.1vw;
      bottom: -0.1vw;
      border-radius: 0.5vw;
      border: 0.1vw solid white;
    }
  }

  .content {
    // width: 100%;
    // height: 100%;
    // text-align: center;
    // width: fit-content;
    margin: auto;

    opacity: 0.6;
    .plus-btn {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin: auto;
      text-align: center;
      border: 0.1vw solid #ffffff;
      border-radius: 0.4vw;
      width: 3.125vw;
      height: 3vw;

      span {
        font-size: 2vw;
        font-weight: bold;
      }
    }

    .title {
      font-weight: bold;
      margin-top: 1vw;
      font-size: 0.9vw;
    }
  }

  &:hover .content {
    opacity: 1;
  }
}
