#billing {
  .section:not(:first-child) {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin-top: 1rem;
  }

  .new-drive {
    p {
      margin-bottom: 0.5rem;
      margin-top: 0.5rem;
    }
  }

  .title {
    margin-top: 1rem;
    font-size: 16px;
    font-weight: bold;
  }

  p {
    margin-top: 0px;
    margin-bottom: 1rem;

    font-size: 12px;
    opacity: 0.8;
  }

  .wallet {
    color: rgb(0, 175, 23);
    font-weight: bold;
  }

  .purchase-licenses {
    margin-top: 0rem;
    margin-bottom: 1rem;
  }

  .cancel-sub-btn,
  .subscribe-btn {
    margin-top: 0rem;
    padding: 0.5rem 0.75rem;
    background-color: rgb(255, 71, 71);
    border-radius: 4px;
    border: none;
    color: white;

    &:hover {
      background-color: rgb(255, 19, 19);
    }
  }

  .subscribe-btn {
    background-color: rgb(71, 83, 255);
    &:hover {
      background-color: rgb(36, 51, 255);
    }
  }

  .subscribe-btn:disabled {
    background-color: grey;
    cursor: not-allowed;
  }
}
