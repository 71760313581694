.animations-props {
  height: 100vh;
  width: 100vw;
  background-color: #100f10;
  color: white;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 3vw;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;

    background-color: rgba(255, 251, 251, 0.2);
    -webkit-box-shadow: inset 0 0 6px rgba(90, 90, 90, 0.7);
    box-shadow: inset 0 0 6px rgba(90, 90, 90, 0.7);
  }
  .search {
    margin: 2vw 1vw;
    // width: calc(100% - 0.5rem);
    width: 94vw;
    .ant-input-group {
      display: inline-block;
    }
    .ant-input-affix-wrapper.search {
      border: 0.5vw solid white;
    }

    .ant-input-search {
      padding: 0;
      border-radius: 5px;
      background-color: white;
      border: none;
      input {
        padding: 1vw 2vw;
        background-color: white;
        color: black;
        border-radius: 2vw;

        font-size: 5vw;
      }
      ::placeholder{
        color: #000000;
      }
      .ant-input-suffix {
        margin: 0px;
        margin-right: 3vw;
        // background-color: #9f9ea1;
        background-color: white;
      }
    }
    button {
      display: none;
    }
  }

  .animations-list {
    padding-left: 2.5vw;
    padding-right: 2.5vw;
    height: 100%;
    width: 100%;
    overflow: auto;

    .animation {
      width: 100%;
      display: flex;
      align-items: center;

      .name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 5vw;
      }

      .play {
        margin-left: auto;
        margin-right: 1vw;
      }

      .play,
      .pause,
      .add {
        cursor: pointer;
        svg {
          width: 6.5vw;
        }
      }
    }
  }
}
