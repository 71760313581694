.list-view:not(.panorama) {
  #items-grid {
    display: flex;
    flex-direction: column;
    padding: 0px;
    margin: 0px;
  }

  #items-grid .item {
    border-bottom: 1px rgba(255, 251, 251, 0.15) solid;
    margin-bottom: 0px;
    padding-top: 2vw;
    padding-bottom: 2vw;
    display: flex;
    align-items: center;
  }

  #items-grid .item:hover {
    background-color: rgba(255, 251, 251, 0.1);
  }

  #items-grid .item .item-card {
    margin-right: 2.5vw;
  }

  #items-grid .item .name {
    flex: 1;
    word-break: break-all;
  }

  #items-grid {
    flex: 1;
  }
  #items-grid .item {
    width: 100%;
    padding-left: 2vw;
    .item-card {
      height: 25vw;
      width: 25vw;
    }

    h4 {
      font-size: 2.5vw;
    }
  }

  //   /* Wearables, small mobile devices (less than 320px)*/
  //   @media (max-width: 319px) {
  //     #items-grid {
  //       flex: 1;
  //     }
  //     #items-grid .item {
  //       width: 100%;
  //     }
  //   }

  //   /* Mobile devices (portrait phones, 320px and up) */
  //   @media (min-width: 320px) {
  //     #items-grid {
  //       flex: 1;
  //     }
  //     #items-grid .item {
  //       width: 100%;
  //     }
  //     #items-grid .item .item-card {
  //       height: 110px;
  //       width: 50%;
  //     }
  //   }

  //   /* Experimental breakpoint */
  //   @media (min-width: 400px) {
  //     #items-grid {
  //       flex: 1.5;
  //     }
  //     #items-grid .item .item-card {
  //       height: 110px;
  //       width: 35%;
  //     }
  //   }

  //   /* Small devices (landscape phones, 576px and up) */
  //   @media (min-width: 576px) {
  //     #items-grid {
  //       flex: 2;
  //     }
  //     #items-grid .item .item-card {
  //       height: 110px;
  //       width: 25%;
  //     }
  //   }

  //   /* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
  //   @media (min-width: 768px) {
  //     #items-grid {
  //       flex: 3;
  //     }
  //     #items-grid .item .item-card {
  //       height: 110px;
  //       width: 20%;
  //     }
  //   }

  //   /* Large devices (desktops, 992px and up) */
  //   @media (min-width: 992px) {
  //     #items-grid {
  //       flex: 4;
  //     }
  //     #items-grid .item .item-card {
  //       height: 110px;
  //       width: 15%;
  //     }
  //   }

  //   /* Extra large devices (large desktops, 1200px and up) */
  //   @media (min-width: 1200px) {
  //     #items-grid {
  //       flex: 5;
  //     }
  //     #items-grid .item .item-card {
  //       height: 100px;
  //       width: 10%;
  //     }
  //   }
}
