.control-btns{
    position: absolute;
    right: 10px;
    z-index: 1000;
}

.control-btns div {
    width: 35px;
    height: 35px;
    margin: 1rem 5px;
    cursor: pointer;
    background-color: rgb(0,0,0, 0.5);
    padding: 5px;
    opacity: 0.5;
    border-radius: 25%;
}

.control-btns div:hover {
    opacity: 1;
    background-color: rgb(0,0,0, 0.3);
}

.control-btns div img {
    max-width: 100%;
}