.screen {
  // flex-basis: 100%;
  width: 78vw;
  height: 100vh;
  // background-color: #11202e;
  // padding-bottom: 5vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;

  &.original {
    background-color: #11202e !important;
  }
  &.black {
    background-color: black !important;
  }
  /* min-width: 800px; */
  .right-aligned {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  .screen-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    height: 100vh;
  }
}

/* .screen-column-container {
  flex-basis: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.screen-row-container {
  flex-basis: 92%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
} */
.screen-top {
  // flex-basis: 25%;
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-left: 5vw;
  margin-right: 5vw;
  margin-top: 4.5vw;

  // margin-bottom: 3rem;
  // margin-left: 80px;
  /* justify-content: space-between; */
  .title {
    font-family: Roboto;
    // font-weight: 800;
    // font-size: 30px;
    font-size: 1.8vw;
    font-weight: normal;
    // line-height: 35px;
    color: white;
    // margin-right: 3rem;
  }

  button {
    width: 6.2vw;
    height: 2vw;

    white-space: nowrap;
    padding: 0%;
    // height: 80%;
    font-size: 0.8vw;
    background-color: transparent;
    border: 0.1vw solid rgba(255, 255, 255, 0.6);
    color: rgba(255, 255, 255, 0.9);
    border-radius: 0.25vw;
  }

  .share-btn {
    margin-left: 0.93vw;

    &:hover {
      color: rgba(255, 255, 255, 1);
      border: 0.15vw solid rgba(255, 255, 255, 0.9);
    }
  }

  .new-scene-btn {
    margin-left: 0.9vw;
    color: #ffc233;
    border-color: #ffc233;

    &:hover {
      color: #ffb300;
      border: 0.15vw solid #ffb300;
    }
  }
}

.second-row {
  display: flex;
  align-items: center;
  margin: 1vw 5vw 1.5vw 5vw;

  color: white;

  .tabs {
    display: flex;

    .tab {
      color: rgba(255, 255, 255, 0.6);
      font-size: 0.9vw;
      margin-right: 1.25vw;
      cursor: pointer;

      &.active {
        font-weight: bold;
        color: white;
      }
    }
  }

  .scene-sort-dropdown {
    display: flex;
    width: 9vw;
    font-size: 0.9vw;
    cursor: pointer;
    font-weight: bold;
    img {
      margin-left: auto;
      width: 0.6vw;
    }
  }

  .view-icon {
    cursor: pointer;
    margin-left: 2.5vw;
    width: 1vw;
    img {
      width: 1vw;
      height: 1vw;
    }
  }

  .project-settings-dropdown {
    margin-left: 2.7vw;
  }
}

.icon-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  margin-left: 80px;
  width: 70px;
}

.icon-view {
  margin-right: 15px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  //
}

.icon-view:hover {
  cursor: pointer;
}

/* ------------------------------------------------------------- Scene cards */
.grid-scene-container {
  display: grid;
  margin: -1vw 4vw 1vw 4vw;
  padding: 1vw 1vw 1vw 1vw;
  // width: 100%;
  overflow: auto;
  overflow-y: overlay;
  // padding-bottom: 5vw;
  // overflow-x: hidden;
  // overflow-y: overlay ;
  // margin-right: 80px;
  // margin-left: 80px;
  grid-gap: 2.2vw 2.2vw;
  // grid-template-columns: repeat(auto-fill, minmax(32.8vw, 1fr));
  grid-template-columns: 32.8vw 32.8vw;
  // grid-template-rows: repeat(auto,10px 10px);

  grid-auto-rows: 14vw;
  // grid-auto-rows: 10px;

  &::-webkit-scrollbar {
    width: 0.5vw;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.6);
    border-radius: 1vw;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgb(92, 92, 92);
    border-radius: 1vw;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
.save-sort-btn {
  margin: 0.25vw 4vw;

  button {
    background-color: #005c81;
    color: white;
    border: none;
    padding: 0.25vw 1vw;
    font-size: 0.9vw;
    border-radius: 20px;
    outline: none;

    &:hover {
      background-color: #33738d;
    }
  }
}

.sort-arrows {
  // margin-left: 0.5vw;
  svg {
    opacity: 0.6;
    width: 1.5vw;
    height: 1.5vw;
    // margin-left: 0.5vw;
    padding: 0.3vw 0.3vw;

    &:hover {
      opacity: 1;
    }
  }
}

.scene-list-container {
  color: white;
  padding-left: 4vw;
  padding-right: 0.5vw;
  margin-right: 4.5vw;
  margin-bottom: 1vw;

  overflow: auto;
  overflow-y: overlay;

  &::-webkit-scrollbar {
    width: 0.5vw;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.6);
    border-radius: 1vw;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgb(92, 92, 92);
    border-radius: 1vw;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .scene-row {
    display: flex;
    width: 100%;

    padding-left: 1vw;

    //

    &:hover:not(.header):not(:last-child) {
      // background-color: rgba(255, 255, 255, 0.6);
      box-shadow: 0vw 0vw 1vw 0.05vw rgba(0, 0, 0, 0.5);
      cursor: pointer;
    }

    &.header {
      margin-bottom: 0.75vw;
      * {
        color: rgba(255, 255, 255, 0.3) !important;
      }
    }

    &:not(.header) {
      height: 8vw;
      padding-top: 1vw;
      padding-bottom: 1vw;
      margin-bottom: 1vw;
      border-radius: 0.5vw;
      align-items: center;

      .scene-image {
        height: 100%;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
      }
    }

    & > div {
      padding-left: 1vw;
      margin-right: 0.5vw;

      font-size: 0.9vw;
      color: rgba(255, 255, 255, 0.6);
    }

    .scene-image {
      width: 18% !important;
      border-radius: 0.5vw;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .scene-num {
      width: 3%;
    }
    .sort-arrows {
      width: 2%;
    }
    .scene-name {
      width: 20%;
    }
    .scene-date {
      width: 15%;

      display: flex;
      flex-direction: column;

      .name {
        font-weight: bold;
      }
      .date {
        font-style: italic;
      }
    }
    .scene-creator {
      width: 12%;
    }
    .scene-members {
      width: 18%;
    }
    .scene-options {
      width: 10%;

      display: flex;
      justify-content: flex-end;
      align-items: center;

      padding-right: 0px;
      margin-right: 0px;

      cursor: pointer;

      & > * {
        margin-left: 0.75vw;
        width: 1.25vw;
        height: 1.25vw;

        border-radius: 0.5vw;
        //

        &:hover {
          box-shadow: 0vw 0vw 0.5vw 0.1vw white;
        }
      }
    }

    .screen-top-profile-icon-container {
      padding: 0px;
      * {
        padding: 0px;
        width: 1.25vw;
        height: 1.25vw;
      }
    }

    .create-scene-btn {
      padding-left: 0px;
      width: 100%;
      color: rgba(255, 255, 255, 1);
      box-shadow: none !important;
      padding-top: 0.5vw;
      .plus-btn {
        width: 2.3vw;
        height: 2.3vw;
        font-weight: bold;
        span {
          font-size: 2vw !important;
        }
      }

      .title {
        margin-top: 0.5vw;
      }
    }
  }
}

.screen-content-card {
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  box-shadow: 0 0 0.25vw 0.1vw rgba(0, 0, 0, 0.4);
  border: 0.1vw solid #11202e;
  border-radius: 1vw;

  &:hover {
    border: 0.1vw solid white;
  }
}

.screen-content-card-icon:hover {
  // box-shadow: 0 1vw 1vw 0 rgba(0, 0, 0, 0.8);
}

.screen-content-card-icon-container {
  display: flex;
  justify-content: flex-end;

  margin-top: 0.75vw;
  margin-right: 1.5vw;

  img {
    margin-left: 0.5vw;
  }
}

.screen-content-card-icon {
  // padding: 0.25rem;
  border-radius: 0.1vw;
  // margin-top: 0.25rem;
  // margin-right: 0.25rem;
  cursor: pointer;
  //
  width: 1vw;
  height: 1vw;
}

.screen-content-card-title {
  // flex-basis: 20%;
  // order: 2;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 0.9vw;
  text-shadow: 2px 2px 3px black;

  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1vw;

  // font-size: 18px;
  // line-height: 24px;

  // padding-left: 10%;
  // padding-bottom: 1.5rem;
  color: #fff;

  display: flex;
  align-items: center;
}

.image1 {
  border-radius: 1vw;
  height: 100%;
  object-fit: contain;
}

.scene-index {
  margin-right: 1rem;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
}

.scene-loader {
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
}

.scene-list-item-container {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  cursor: pointer;
  align-items: center;
  //

  &:hover {
    box-shadow: 1px 1px 10px 1px rgba(255, 255, 255, 0.6);
  }
}
.scene-list-item-image {
  height: 70px;
  width: 140px;
  border-radius: 5%;
  margin: 10px;
  margin-left: 40px;
  background-repeat: no-repeat;
  background-size: cover;
}
.scene-list-item-content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.scene-list-item-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: 1000;
  font-size: 18px;
  line-height: 24px;
  padding-left: 20px;
  color: black;

  display: flex;
  align-items: center;
}

.scene-list-item-content .public-tag {
  margin-left: 0.5rem;
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
  font-size: 10px;
  cursor: pointer;

  &.public {
    background-color: rgb(161, 255, 158);
  }
  &.public:hover {
    background-color: rgb(44, 255, 37);
  }

  &.private {
    background-color: rgb(255, 245, 158);
  }
  &.private:hover {
    background-color: rgb(250, 227, 25);
  }
}

.scene-list-item-container .members {
  margin-left: auto;
}

.last-modified {
  margin-left: 1rem;
  margin-right: 1rem;
  font-style: italic;
  font-size: 12px;
}

.scene-list-item-settings {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
}

.scene-order-types {
  background-color: white;
  width: 10vw;
  font-size: 0.8vw;
  border-radius: 0.5vw;

  div {
    padding: 0.2vw;
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}
