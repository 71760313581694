.time {
  & {
    margin-top: 7vw;
  }

  * {
    font-size: 4.5vw;
  }

  img {
    width: 5vw;
  }

  input {
    color: black;
    border-radius: 40px;
    padding-left: 1vw;
    padding-right: 1vw;
    border: 0px;
    background-color: rgba($color: #ffffff, $alpha: 0.8);
  }

  .day-time {
    align-items: flex-start;
    flex-direction: column;
  }

  .time-text {
    display: flex;
  }

  .time-input-text {
    margin-top: 3vw;
    display: flex;
    flex-direction: row;

    span {
      margin-right: 1vw;
    }
  }

  .time-input {
    width: 100%;

    .time-picker {
      background-color: #cfd2d5;
      border: none;
      border-radius: 5vw;
      input {
        font-size: 4vw;
        background-color: #cfd2d5;
      }
    }
  }

  .ant-slider {
    margin-left: 5vw;
    margin-right: 5vw;
    flex-grow: 1;
  }

  .slider {
    display: flex;
    align-items: center;

    margin-top: 2vw;
    margin-bottom: 2vw;
  }

  .slider span {
    flex-shrink: 0;
    // padding-top: 3vw;
    font-size: 3.25vw;
  }

  button {
    background-color: #2d445a;
    border: 0px;
    border-radius: 20px;
    margin-bottom: 2vw;
    padding: 1.5vw 6vw;
    transition: 300ms;
    outline: none;

    &:hover {
      background-color: #3e5f7e;
    }

    &:active {
      background-color: #263a4d;
    }
  }

  .card {
    background-color: #2d445a;
    height: 2vw;
  }

  .info {
    color: #cfd2d5;
  }

  .info > div {
    display: flex;
    margin-top: 3vw;
    margin-bottom: 1rem;
  }

  .info img {
    margin-right: 3vw;
  }

  .date {
    display: flex;
    align-items: center;
  }

  .date input {
    margin-right: 2vw;
  }
  .date button {
    margin-top: 1vw;
  }

  .location {
    margin-bottom: 1vw;
    display: flex;
    align-items: center;
  }

  .location .address-input {
    display: flex;
    align-items: flex-start;
    width: 100%;
  }

  .location .address-input .search-location-input {
    margin-right: 3vw;
    flex-grow: 1;
  }

  .location .address-input .ant-select-selector {
    border-radius: 15px;
    background-color: #cfd2d5;
    width: 100%;
  }

  .address-input .ant-select-selection-placeholder {
    color: rgba($color: #000000, $alpha: 0.5) !important;
  }

  .location .address-input button {
    flex-shrink: 0;
  }

  .map {
    margin-right: 1rem;
    margin-left: 1rem;
    background-color: #2d445a;
    height: 124px;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .recalculate-lights button {
    padding: 2vw 4vw;
  }
}
