.backlot-wizard {
  height: 100vh;
  width: 100vw;

  background-color: #05192c;

  overflow: auto;
  overflow-y: scroll;

  * {
    color: white;
  }

  .top-bar {
    display: flex;
    padding-top: 0.5rem;
    width: 100%;
    .save-character {
      margin-left: auto;
    }

    .close-wizard {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }

    & > div {
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #1c3853;
      border-radius: 50%;

      cursor: pointer;

      &:hover {
        background-color: #2b567e;
        box-shadow: 1px 1px 5px 2px rgb(0, 0, 0);
      }
    }
  }

  .tabs {
    margin: auto;
    margin-top: 1rem;
    padding: 0rem 0.75rem;

    display: flex;
    justify-content: center;

    .tab {
      padding: 0.7rem 1.5rem;
      cursor: pointer;
      border: 1px solid #2d445a;
      font-weight: bold;

      &.selected {
        background-color: #2d445a;
      }

      &:hover {
        background-color: #2d445a;
      }

      &:first-child {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
      }

      &:last-child {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
      }
    }
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;

    background-color: rgba(255, 251, 251, 0.05);
    -webkit-box-shadow: inset 0 0 6px rgba(90, 90, 90, 0.7);
    box-shadow: inset 0 0 6px rgba(90, 90, 90, 0.7);
  }

  .wizard {
    padding: 1rem;
    padding-left: 2.5rem;
    margin-top: 1rem;
    * {
      color: #cfd2d5;
    }

    .color-input {
      display: flex;
      align-items: center;
      margin-bottom: 2rem;
      span {
        width: 60px;
        font-size: 12px;
      }
    }

    .color-picker-box {
      margin-left: 1rem;
      width: 25px;
      height: 25px;
      cursor: pointer;
      border-radius: 50%;
    }

    h3 {
      color: white;
      margin-top: 3rem;
      margin-bottom: 3rem;
      margin-left: -1rem;
    }

    .option-input {
      display: flex;
      align-items: center;
      margin-bottom: 1.5rem;
      .name {
        width: 70px;
        font-size: 12px;
      }

      .ant-select-selector {
        width: 260px;
        height: 32px;
        background-color: #05192c;
        border-radius: 20px;
        img {
          width: 20px;
          height: 20px;

          margin-right: 1rem;
        }
      }

      .slider {
        margin-left: 1rem;
        width: 70%;
      }

      .undo {
        margin-left: 0.25rem;
        cursor: pointer;
        opacity: 0.7;
        &:hover {
          opacity: 1;
        }
      }
    }

    .option-input-grid {
      .name {
        display: flex;
        align-items: center;
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-size: 12px;
        font-weight: bold;

        cursor: pointer;

        svg {
          margin-right: 0.5rem;
        }
      }

      .no-data {
        display: block;
        font-size: 12px;
        margin-bottom: 3rem;
      }

      .grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
        column-gap: 7px;
        row-gap: 7px;

        &.collapsed {
          display: none;
        }

        .grid-item {
          width: 70px;
          height: 90px;
          padding: 10px;

          background-color: #152a3f !important;
          border: 2px solid transparent;
          cursor: pointer;

          &.selected {
            border: 2px solid grey;
            background-color: #1f3e5a !important;
          }

          &:hover {
            background-color: #2b5780 !important;
          }

          .grid-img {
            width: 100%;
            height: 60px;
            background-size: contain !important;
            background-repeat: no-repeat !important;
            background-position: center !important;
          }

          .color-picker {
            position: relative;
            left: -12px;
            bottom: -7px;
            width: 70px;
            height: 10px;

            background-color: white;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
          }
        }
      }
    }
  }
}

.ant-tooltip-inner {
  padding: 0px;
}

.character-option-selector-drop-menu {
  background-color: #02101d;
  margin: 0px;
  padding: 0;
  border-radius: 8px;
  border: 0.5px solid rgba(255, 255, 255, 0.3);
  font-size: 12px;
  color: white !important;

  .ant-select-item-option-content,
  .ant-empty-description {
    color: white !important;
  }

  .ant-select-item-option-selected {
    background-color: rgba(#ffff, 0.05) !important;
  }
  .ant-select-item-option-active {
    background-color: rgba(#ffff, 0.1) !important;
  }
  .tag {
    color: rgba(255, 255, 255, 0.4);
    font-weight: bold;
    font-size: 10px;
    margin-right: 0.5rem;
  }

  img {
    width: 40px;
    height: 40px;

    margin-right: 1rem;
  }

  .rc-virtual-list-scrollbar-thumb {
    background: rgba(#464646, 1) !important;
  }
}

.face-wizard {
  .grid {
    margin-bottom: 2rem;
  }
}

.clothing-wizard {
  .option-input-grid:first-child .name {
    margin-top: -1rem !important;
  }
}
