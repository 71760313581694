#backlot-share {
  & {
    background-color: #132434;
    color: white;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  .no-access {
    position: absolute;
    left: 50%;
    top: 50%;
    font-size: 30px;
    color: gray;
    transform: translate(-50%, -50%);
  }
  .share-header {
    font-size: 26px;
    padding: 1rem;
  }

  .share-form {
    padding: 1rem 2rem;
    flex-shrink: 0;
  }

  form {
    //   width: 100%;
    display: flex;
  }

  input {
    flex-grow: 1;
    padding: 0.25rem 0.5rem;
    margin-right: 1rem;
    color: black;
  }

  button {
    border-radius: 10px;
    padding: 0.5rem 1.5rem;
  }

  p {
    padding-top: 1rem;
    font-size: 14px;
  }

  .team {
    & {
      background-color: #f8f8f8;
      background-color: #234666;
      border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);

      padding: 2rem;

      margin-left: 2rem;
      margin-right: 2rem;

      border-radius: 10px;

      flex-grow: 1;
      height: 100%;

      overflow: auto;
    }

    .user {
      display: flex;
      margin-top: 1.5rem;
    }

    .name {
      flex-grow: 1;
    }

    .member {
      color: rgb(158, 158, 158);
    }

    .dropdown > button,
    .dropdown > button:focus,
    .dropdown > button:active {
      background-color: rgba(255, 255, 255, 0);
      color: white;
      border-color: rgba(255, 255, 255, 0) !important;
      padding: 0px;
      outline: none;
      box-shadow: none;
    }

    .dropdown button:focus,
    .dropdown button:active {
      background-color: rgba(255, 255, 255, 0.1);
      color: white;
      border: none !important;
    }

    .dropdown-item {
      color: red !important;
    }
  }

  .share-footer {
    & {
      flex-shrink: 0;
      margin-top: 1rem;
      margin-bottom: 1rem;
      justify-content: left;
      padding-left: 2rem;
    }

    svg {
      fill: none;
      stroke-width: 2rem;
      color: #0094ff;
      margin-right: 0.5rem;
      
    }

    a {
      color: #0094ff !important;
      text-decoration: inherit;
      margin-right: 2rem;
    }

    a:hover svg,
    a:hover {
      color: #7bc8ff !important;
    }
  }

  .team::-webkit-scrollbar {
    width: 0.5rem;
  }

  .team::-webkit-scrollbar-track {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(104, 104, 104, 0.7);
  }

  .team::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(255, 251, 251, 0.05);
    -webkit-box-shadow: inset 0 0 6px rgba(104, 104, 104, 0.7);
  }
}
