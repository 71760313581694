.team-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 1.8vw;
  color: white;
}
.screen-container-padding {
  /* padding-left: 80px; */
  /* padding-right: 80px; */
  padding-left: 5vw;
  padding-right: 5vw;
  margin-top: 4.5vw;
}
.team-cards {
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: flex-start;
  // align-items: stretch;
  margin-top: 2vw;

  display: grid;
  grid-gap: 2.2vw 2.2vw;
  grid-template-columns: 15vw 15vw 15vw 15vw;

  grid-auto-rows: 14vw;
}

/* .team-col{
    max-height: 250px;
    max-width: 170px;
  } */

.team-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  // 
  /* flex: 0 0 250px; */
  /* padding: 20px; */
  border-radius: 1vw;
  height: max-content;
  background: #ffffff;
  /* max-height: 250px; */
}

.team-card:hover {
  box-shadow: 0 12px 20px 0 rgba(0, 0, 0, 0.8);
}

.team-card > div {
  text-align: center;
}

.team-member-img {
  /* border-radius: 10px 10px 0 0; */
  border-radius: 50%;
  /* width: 100%; */
  height: 5vw;
  object-fit: cover;
  margin: 1vw 0rem;
}

.team-member-information-container {
  border: 1px solid #f7f7f7;
  box-sizing: border-box;
  height: 7vw;
  border-radius: 0 0 10px 10px;

  background: #ffffff;
}
.team-member-name {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 1.25vw;
  // line-height: 18px;
  // margin: 10%;
  margin: 1vw;
  color: #353535;
}
.team-member-job {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 0.9vw;
  // line-height: 20px;
  margin: 1vw;
  color: #686868;
}

.admin-badge {
  /* width: 59px;
    height: 23px; */
  background: #132434;
  color: white;
  position: absolute;
  right: 0px;
  bottom: 0px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-style: normal;
  font-weight: 500;
  font-size: 0.75vw;
  text-align: center;

  padding: 0.25vw 0.75vw;
}
