.input-with-image-library input {
  padding-top: 1vw;
  padding-bottom: 1vw;
  padding-left: 1vw;
}

.input-with-image-library {
  /* border-radius: 10px; */
  border-radius: 4vw;
  display: flex;
  align-content: flex-start;
  /* align-items: baseline; */
  align-items: center;
  border: none;
  box-sizing: border-box;
  border-width: 0px;
}

.input-with-image-library.focus {
  border: black solid 0px !important;
}

.input-with-image-library input {
  border: none;
  color: inherit !important;
  width: 100%;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;
  background-color: inherit;
  border-width: 0;
}

.input-with-image-library input:active,
.input-with-image-library input:focus {
  outline: none;
  border: none;
  border-width: 0;
  box-shadow: none;
  color: inherit !important;
}

.input-with-image-library input:-webkit-autofill,
.input-with-image-library input:-webkit-autofill:hover,
.input-with-image-library input:-webkit-autofill:focus,
.input-with-image-library input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
}

.input-with-image-library ::placeholder {
  /* Firefox */
  color: rgba(0, 0, 0, 0.5) !important;
}

.input-with-image-library :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(0, 0, 0, 0.5) !important;
}

.input-with-image-library ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgba(0, 0, 0, 0.5) !important;
}

.input-with-image-library .ant-input-affix-wrapper {
  background-color: transparent;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  color: white;
}

.input-with-image-library .ant-input-affix-wrapper:focus {
  outline: none;
  box-shadow: none !important;
  border: none;
}

.input-with-image-library .ant-input-clear-icon {
  color: rgba(255, 255, 255, 0.2);
}

.input-with-image-library .ant-input-clear-icon:hover {
  color: white;
}
