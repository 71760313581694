.team-project-tab {
  //   max-height: 550px;
  //   height: 400px;
  padding: 0px;
  overflow: hidden;

  .ant-modal-body {
    padding: 0px;
  }

  .drop-arrow {
    cursor: pointer;
  }

  .add-member {
    margin: 0.5rem 1rem;
    // width: 100%;
    display: flex;
    align-items: center;

    .member-text-box {
      width: 100%;
      margin-right: 1rem;
    }
    input {
      border: 1px solid rgba($color: #000000, $alpha: 0.3);
      border-radius: 5px;
      padding: 0.25rem 0.75rem;
      width: 100%;
    }

    .add-member {
      margin-left: auto;
    }
    button {
      margin-left: auto;
      padding: 0.25rem 1.25rem;
      background-color: #0094ff;
      border-radius: 4px;
      color: white;
      box-shadow: none;
      border: none;
      font-weight: bold;
    }

    button:disabled {
      background-color: rgb(189, 189, 189);
      cursor: not-allowed;
    }
  }

  .all-members {
    // height: 100%;
    overflow: auto;
  }

  .project-type-dropdown {
    display: flex;
    margin-bottom: 1rem;
    margin: 1rem;
    .drop-arrow {
      margin-left: auto;
    }
  }

  .editors {
    padding-bottom: 1rem;
  }

  .reviewers {
    opacity: 0.6;
    border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
  }

  .member {
    display: flex;
    margin: 1rem 1.5rem;
    margin-bottom: 1rem;
    .member-role {
      font-weight: bold;

      margin-left: auto;
      margin-right: 0.5rem;
    }
  }
}
.project-type {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  padding-top: 1rem;

  p {
    margin: 0;
    margin-left: 1rem;
    margin-right: 0.5rem;
    line-height: 14px;
  }
}
