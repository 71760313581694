.personal-project-tab {
  form {
    display: flex;
    align-content: space-between;
    align-items: stretch;

    padding-left: 1rem;
    padding-right: 1rem;

    input {
      flex-grow: 1;
      margin-right: 1rem;
      padding: 0.5rem;
      border: 1px solid grey;
    }

    select {
      margin-right: 1rem;
      border: none;
      outline: none;
    }

    button {
      display: flex;
      align-items: center;
      .loader {
        margin-right: 0.5rem;
      }

      background-color: #0094ff;
      outline: none;
      padding: 0.5rem 1.5rem;
      border-radius: 5px;
      border: none;
      color: white;

      &:disabled {
        background-color: grey;
        cursor: not-allowed;
      }
    }
  }

  p {
    margin: 0.5rem 1rem;
  }

  .members {
    background-color: #f8f8f8;
    padding: 1rem;

    h2 {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 1rem;
    }

    .member {
      display: flex;
      margin-bottom: 1rem;
      .name {
        flex-grow: 1;
      }

      .loader {
        margin-right: 3rem;
      }

      select {
        margin-right: 1rem;
        border: none;
        outline: none;

        background-color: rgba(0, 0, 0, 0);
      }

      .remove-btn {
        background-color: red;
        color: white;
        border: none;
        border-radius: 4px;
      }
    }
  }
}
