.main-settings-modal {
  .ant-modal-close {
    display: none;
  }

  .res-btn-group {
    display: flex;

    margin-top: 1rem;
    .res-btn {
      padding: 0.25rem 1rem;
      margin-right: 1rem;
      background-color: #365c77;
      color: white;
      border: none;
      border-radius: 15px;

      outline: none;

      cursor: pointer;

      &:hover {
        background-color: #81caff;
      }

      &.selected {
        background-color: #4eb2fa;
      }

      &.disabled {
        background-color: grey;
        cursor: not-allowed;
      }
    }
  }

  input[type='text'],
  select {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }

  input[type='submit'] {
    width: 100%;
    background-color: #4caf50;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  input[type='submit']:hover {
    background-color: #45a049;
  }

  .icon-wrapper {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .icon-wrapper span {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45);
  }

  .ant-slider {
    flex-grow: 1;
    margin-left: 1rem;
    margin-right: 1rem;

    .ant-slider-rail {
      background-color: rgb(206, 206, 206);
    }
  }

  .ant-modal-content,
  .ant-modal-header {
    border-radius: 5px;
    background-color: #f2f2f2;
    // padding: 20px;
  }
}
