/* ------------------------------------------- Search Results */

.color-select {
  border-radius: 10px;
  color: white;
  * {
    transition: none !important;
    font-size: 1.8vw;
  }
  padding: 0px;
  &,
  .ant-select-item-empty,
  .ant-select-item-option-content,
  .search-library-select,
  .ant-empty-description {
    color: white;
    background-color: #4d4d4d !important;
  }

  .ant-select-item:hover,
  .ant-select-item:hover .ant-select-item-option-content {
    background-color: #7a7a7a !important;
  }
}

#home-search-results {
  width: 100%;
  flex-grow: 1;
  padding-top: 3vw;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: stretch;
  align-items: stretch;

  overflow: auto;

  .select-boxes {
    display: flex;
    width: 100%;
  }

  .select {
    margin-right: 0.5vw;
    margin-left: 0.5vw;
    margin-bottom: 2vw;
    width: 33.3%;

    // &:last-child {
    //   width: 50%;
    // }

    .ant-select-selection-placeholder {
      color: rgb(175, 175, 175) !important;
    }

    .ant-select-clear {
      &,
      & span,
      & svg {
        background-color: #414c57;
      }
    }

    &,
    & .ant-select-selector {
      background-color: #414c57;
      border-radius: 4vw !important;
      border: none;
      font-size: 2vw;
      // height: 5vw;
    }
  }

  // .select,
  // .select .ant-select-selector {
  //   background-color: #414c57;
  //   border-radius: 20px !important;
  //   border: none;
  // }

  .tags-container,
  .options-container {
    width: 100%;
    flex-shrink: 0;
    display: flex;
    flex-wrap: wrap;
    padding-left: 0.25vw;
    margin-bottom: 1vw;
  }

  .tags-container .ant-tag.checked {
    box-shadow: 0px 0px 10px 4px rgba($color: #ffffff, $alpha: 0.2) !important;
  }

  .tags-container .ant-tag:hover {
    box-shadow: 0px 0px 0px 5px rgba($color: #ffffff, $alpha: 0.1) !important;
  }

  .options-container .ant-tag.checked {
    box-shadow: 0px 0px 5px 4px rgba($color: #ffffff, $alpha: 0.2) !important;
    color: black;
  }

  .options-container .ant-tag:hover {
    box-shadow: 0px 0px 0px 5px rgba($color: #ffffff, $alpha: 0.1) !important;
  }

  .ant-tag {
    margin-bottom: 0.25vw;
    // margin-right: 1vw;
    padding: 0vw 1.75vw;
    font-size: 2vw;
    height: 4vw;
    border-radius: 3vw;
  }

  .check-tag {
    margin-bottom: 0.25vw;
    margin-right: 1vw;
    outline: none;
    padding: 0vw 1.75vw;
    font-size: 2vw;
    // height: 4vw;
    border-radius: 3vw;
    border-style: solid;
    border-width: 0.25vw;
  }

  .items-container {
    flex-grow: 1;
    width: 100%;
    margin-bottom: 2vw;
    overflow: auto;

    .carousel-img {
      object-fit: cover;
    }
    .carousel-control-prev-icon,
    .carousel-control-next-icon {
      width: 5vw;
    }

    /* -------------------------------------------------- Scroll-bar */

    &::-webkit-scrollbar {
      width: 1vw;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      box-shadow: inset 0 0 6px rgba(104, 104, 104, 0.7);
      -webkit-box-shadow: inset 0 0 6px rgba(104, 104, 104, 0.7);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: rgba(255, 251, 251, 0.05);
      box-shadow: inset 0 0 6px rgba(104, 104, 104, 0.7);
      -webkit-box-shadow: inset 0 0 6px rgba(104, 104, 104, 0.7);
    }
  }

  #items-grid {
    margin-top: 1vw;
    grid-template-columns: repeat(4, 18.5vw);

    .item {
      // height: 30vw;
      margin-bottom: 2vw;
      .img-container {
        height: 18.5vw;
      }
    }

    &::-webkit-scrollbar {
      width: 1vw;
    }
  }

  // @media (max-width: 319px) {
  //   .tags-container {
  //     margin-bottom: 3vw;
  //   }
  //   .ant-tag {
  //     margin-bottom: 0.5rem;
  //     padding: 0.25rem 0.25rem;
  //     font-size: 7px;
  //     border-radius: 10px;
  //   }
  // }

  // @media (min-width: 320px) {
  //   .tags-container {
  //     margin-bottom: 1.5rem;
  //   }
  //   .ant-tag {
  //     margin-bottom: 0.5rem;
  //     padding: 0.25rem 0.5rem;
  //     font-size: 10px;
  //     border-radius: 15px;
  //   }
  // }

  // @media (min-width: 576px) {
  //   .tags-container {
  //     margin-bottom: 3vw;
  //   }
  //   .ant-tag {
  //     margin-bottom: 3vw;
  //     padding: 0.5rem 0.75rem;
  //     font-size: 16px;
  //     border-radius: 20px;
  //   }
  // }
}
