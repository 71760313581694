.in-app-purchases-table {
  margin-top: 1rem;
  .ant-table-content {
    min-width: fit-content !important;
  }

  .ant-table-thead {
    tr {
      background-color: rgba($color: #000000, $alpha: 0.1);
    }
    * {
      background: rgb(223, 223, 223) !important;
      color: black !important;
    }
  }

  .ant-table-tbody,
  .ant-table-cell {
    // background-color: white !important;
    color: black;

    .ant-table-cell {
      background-color: white !important;
      color: rgb(0, 0, 0) !important;
    }
  }

  tr {
    border: 4px solid red !important;
  }

  .status {
    width: fit-content;
    color: black;
    font-weight: bold;
    padding: 0.25rem 0.5rem;
    border-radius: 10px;
  }
}
