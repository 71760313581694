.settings-menu {
  padding: 5vw 6vw;
  height: 100vh;
  overflow: auto;
  background-color: #11202e;
  // body {
  //   // margin-top: 20px;
  //   color: #1a202c;
  //   text-align: left;
  //   background-color: #e2e8f0;
  // }
  .main-body {
    padding: 20vw;
  }

  .sidebar {
    display: block;
    background-color: white;
    width: 30vw;
    margin-right: 1vw;
    padding: 1vw;

    svg {
      width: 1.75vw;
      height: auto;
    }
  }

  .main-content {
    width: 55vw;
    padding: 1.5vw;
    background-color: white;
  }

  .nav {
    height: fit-content !important;
  }

  .nav * {
    margin: 0rem;
    text-align: left;
    font-size: 1.1vw;
  }

  .nav-pills {
    margin-top: 0rem;
    width: 100%;
  }

  .selected-license {
    text-align: left;
    margin: 0rem !important;
    & > * {
      text-align: left !important;
      margin: 0rem !important;
      margin-top: 1vw !important;
      margin-bottom: 1vw !important;
      padding-left: 1.5vw;
      padding-bottom: 1vw;
      padding-right: 1vw;
      display: flex;
      flex-direction: row;

      border-bottom: 1px solid rgba($color: black, $alpha: 0.2);
      cursor: pointer;
    }

    img {
      // margin-right: 1vw;
      // width: 36px;
      // height: 36px;
      // border-radius: 4px;
    }

    .title {
      display: flex;
      flex-direction: column;
    }

    .license-name {
      font-size: 1.5vw;
      margin-bottom: 0;

      &.no-selection {
        color: grey;
        font-style: italic;
      }
    }

    .role {
      font-size: 1vw;

      .admin-tag {
        font-size: 0.6vw;
        padding: 0.2vw 0.3vw;
        background-color: rgb(0, 153, 255);
        color: white;
        margin-left: 0.5vw;
        border-radius: 3px;
      }
    }

    .arrow {
      margin-left: auto;
      // margin-top: 0.25rem;
    }
  }

  .nav-link {
    color: #4a5568;
    width: 100%;
    text-align: left;

    padding: 0.5vw 1vw;
  }

  .link {
    font-size: 1.25vw;

    svg {
      margin-right: 0.5vw;
    }
  }
  .card {
    box-shadow: 0 1px 4px 4px rgba(0, 0, 0, 0.1),
      0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }

  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    // padding: 1rem;
  }

  .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    background-color: #fff;
    // padding: 1rem;
    color: black;
  }

  .gutters-sm {
    margin-right: -8px;
    margin-left: -8px;
  }

  .gutters-sm > .col,
  .gutters-sm > [class*='col-'] {
    padding-right: 8px;
    padding-left: 8px;
  }
  .mb-3,
  .my-3 {
    margin-bottom: 1rem !important;
  }

  .bg-gray-300 {
    background-color: #e2e8f0;
  }
  .h-100 {
    height: 100% !important;
  }
  .shadow-none {
    box-shadow: none !important;
  }

  .nav-item {
    cursor: pointer;
    border-radius: 50px !important;

    &:not(.active):hover {
      background-color: rgba(0, 0, 0, 0.06);
    }
  }

  .nav-link.disabled {
    opacity: 0.6;
  }
}

.teams-drop-menu {
  // background-color: #0a141d;
  // border: 1px solid rgba($color: white, $alpha: 0.1);
  // padding: 0px;

  // max-height: 400px;
  // min-width: 175px;
  // overflow: auto;

  // li {
  //   // color: white !important;

  //   &:hover {
  //     // background-color: rgba($color: white, $alpha: 0.2);
  //   }
  // }
}

.tab-pane {
  * {
    font-size: 1vw;
  }
  h6 {
    font-size: 1.25vw;
  }

  label {
    font-size: 1.25vw;
  }

  small {
    font-size: 1vw;
  }
}
