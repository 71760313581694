.replies-btn *{
    cursor: pointer;
    font-weight: 500;
    font-size: 12px;
    color: white;
    opacity: 0.5;
  }

  @media (min-width: 576px){
    .replies-btn:hover *{
        opacity: 1;
    }
  }