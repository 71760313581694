.version-list-item-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
}
.version-list-item-content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
}
.version-index {
  margin-right: 1rem;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
}
.version-list-item-image {
  height: 70px;
  width: 140px;
  border-radius: 5%;
  margin: 10px;
  margin-left: 40px;
  background-repeat: no-repeat;
  background-size: cover;
}

.version-list-item-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: 1000;
  font-size: 18px;
  line-height: 24px;
  padding-left: 20px;
  color: black;

  display: flex;
  align-items: center;
}
.modal-revert-button {
  background: #1a76e8;
  border-radius: 4px;
  width: 94px;
  height: 24px;
  /* margin-left: 300px; */
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 14px;
  margin-right: 30px;
  /* identical to box height */

  color: #ffffff;
  border: none;
}
.modal-revert-button:disabled {
  background: grey;
}

.versions .ant-modal-body {
  padding: 0px;
}

.version-list-item-container > .loader {
  margin-right: 3.5rem;
}
