.loader {
  z-index: 1000000;

  display: flex;
  align-items: center;
  align-content: center;
}

.loader.loader-fullscreen {
  padding: 0px;
  margin: 0px;
  border: 0px;
  width: 100vw;
  height: 100vh;

  position: absolute;
  top: 0;
  left: 0;

  background-color: #11202e;
}

.loader.loader-fullscreen .anticon-loading,
.loader .center-spinner {
  // position: relative;
  // top: 50%;
  // left: 50%;
  margin: auto;
  // transform: translate(-50%, -50%);
}
