.manage-addressables-modal {
  .ant-modal-close {
    display: none;
  }

  .title {
    display: flex;
    flex-direction: column;

    text-align: center;
    font-size: 26px;
    font-weight: bold;

    margin-top: 1rem;
    margin-bottom: 3rem;
  }

  .trash-icon {
    opacity: 0.5;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  .ant-table-wrapper {
    margin-bottom: 3rem;
  }
}
