.user-popover {
    box-shadow: rgba(136, 153, 166, 0.2) 0px 0px 15px, rgba(136, 153, 166, 0.15) 0px 0px 3px 1px !important;
    background-color: rgb(21, 32, 43) !important;

    border-bottom-left-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
    min-width: 200px;
    min-height: 200px;
    color:white;
}

.user-popover .arrow {
    display: none !important;
}

.user-content {
    color: white;
}

.user-content .name {
    font-weight: bold;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 0px;
}

.user-content .email {
    color: rgb(136, 153, 166);
}

.user-content .role {
    font-weight: bold;
    font-size: 16px;
}
