#add-member-popconfirm .ant-popover-buttons{
    display: none !important;
}

.non-members-list {
    max-height: 380px;
    overflow: auto;
}

.role-btn {
    margin: 0.5rem 0.5rem 0px 0.5rem;
} 