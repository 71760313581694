#gallery-body {
  & {
    flex-grow: 1;
    background-color: #0a141d;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    overflow: auto;
    align-content: flex-start;
  }

  .image-card {
    height: 200px;
    padding: 1rem;
  }

  .img {
    background-color: #142636;
    height: 100%;
    width: 100%;

    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    cursor: pointer;

    .loader {
      height: 100%;
      background-color: rgba(255, 255, 255, 0.15);
    }
  }

  .img:hover {
    box-shadow: 0px 0px 7px 1px rgba(255, 255, 255, 0.2);
  }

  /* Wearables, small mobile devices (less than 320px)*/
  @media (max-width: 319px) {
    .image-card {
      width: 100%;
      padding: 1.5rem;
    }
  }

  /* Mobile devices (portrait phones, 320px and up) */
  @media (min-width: 320px) {
    .image-card {
      width: 50%;
    }
  }

  /* Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) {
    .image-card {
      width: 33.33%;
    }
  }

  /* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
  @media (min-width: 768px) {
    .image-card {
      width: 20%;
    }
  }

  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
  }

  /* Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
    .image-card {
      height: 300px;
      width: 16.6666%;
    }
  }

  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(104, 104, 104, 0.7);
    box-shadow: inset 0 0 6px rgba(104, 104, 104, 0.7);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(255, 251, 251, 0.05);
    -webkit-box-shadow: inset 0 0 6px rgba(104, 104, 104, 0.7);
    box-shadow: inset 0 0 6px rgba(104, 104, 104, 0.7);
  }
}
