.not-found {
  & {
    background-color: #11202e;
    height: 100vh;
  }

  h1 {
    position: absolute;
    color: rgba(255, 255, 255, 0.5);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
