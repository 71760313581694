.weather {
  & {
    margin-top: 2vw;
  }

  * {
    font-size: 5vw;
  }

  img {
    width: 5vw;
  }

  #body {
    padding-left: 1vw;
    padding-right: 1vw;
  }

  .info {
    color: #cfd2d5;
    display: flex;
    width: 100%;
  }

  .grow {
    flex-grow: 1;
  }

  .sky-items,
  .storm-items {
    & {
      // display: flex;
      //   flex: 1;
      flex-shrink: 0;
      margin-top: 3vw;
      margin-bottom: 3vw;
    }

    .info-card {
      padding: 2vw;
      border-radius: 4px;
    }

    .info-card:hover {
      cursor: pointer;
      background-color: rgba($color: #ffffff, $alpha: 0.1);
    }

    .info-card.selected {
      background-color: rgba($color: #ffffff, $alpha: 0.3);
    }

    & > div {
      margin-bottom: 1vw;
    }

    img {
      margin-right: 3vw;
    }
  }
}
