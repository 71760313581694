.sky {
  & {
    margin-top: 3vw;
  }

  * {
    font-size: 5vw;
  }

  img {
    width: 5vw;
  }

  #body {
    margin-top: 3vw;
    padding-left: 1vw;
    padding-right: 1vw;
  }

  .clouds-check {
    margin-top: 3vw;
  }

  .options {
    & {
      color: #cfd2d5;
      width: 100%;
    }

    img {
      margin-right: 3vw;
    }
  }

  .ant-select {
    width: 100%;
    margin-top: 4vw;
    margin-bottom: 4vw;

    .ant-select-selector {
      border-radius: 10vw;
      background-color: #2d445a;
      color: white;
      outline: none;
      border: none;
    }

    .ant-select-arrow svg {
      fill: white !important;
    }
  }

  .color-picker-box {
    width: 100%;
    height: 7vw;
    border-radius: 4vw;
    margin-top: 5vw;
    margin-bottom: 4vw;
    cursor: pointer;
  }

  .num-input {
    margin-top: 3vw;
    background-color: #2d445a;
    border: none;
    outline: none;
    border-radius: 5vw;
    padding-left: 3vw;
    text-align: center;
  }

  // Sliders
  .ant-slider {
    .ant-slider-rail {
      background-color: rgba($color: #ffffff, $alpha: 0.2);
    }

    .ant-slider-handle {
      outline: none;
      border-color: white;
    }

    .ant-slider-track {
      background-color: white;
    }
  }
}
