.team-member-role{
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    
    color: #000000;
}

.edit-team-member-btn{
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;

    color: #00978d;

    margin: 0px 2rem 0px 5rem;
    cursor: pointer;
}

.edit-team-member-btn:hover{
    text-decoration: underline;
}

.remove-team-member-btn{
    cursor: pointer;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    color: rgb(199, 28, 28)
}

.remove-team-member-btn:hover{
    text-decoration: underline;
}