.comment {
  & {
    margin-top: 15px;
    max-width: 100%;
    /* width: fit-content; */
    word-wrap: break-word;
    word-break: break-all;
  }

  .comment-header {
    display: flex;
    align-items: baseline;
  }

  .title {
    display: flex;
    flex-wrap: wrap;
  }

  .body {
    /* background-color: #1b1b1b; */
    // width: max-content;
    /* max-width: 100%; */
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    padding: 0.25rem 0.6rem;
    /* height: 100%; */
    width: fit-content;
  }

  .name {
    font-weight: 600;
    font-size: 12px;
    color: white;

    margin-right: 0.5rem;
  }

  .position {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: bold;
    font-size: 10px;

    color: #f2f2f2;
  }

  .timestamp {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    display: flex;
    align-items: center;
    text-align: right;

    color: #c4c4c4;
  }

  .text {
    // position: relative;
    font-family: 'Roboto';
    // font-style: normal;
    // font-weight: normal;
    font-size: 12px;
    // line-height: 14px;
    // display: flex;
    // align-items: center;

    /* margin-top: 10px; */
    // padding: 2px 7px;
  }

  .text p {
    margin-bottom: 5px;
  }

  .tag {
    background-color: transparent;
    border: none;
    padding: 0px;
    outline: none;
    white-space: nowrap;
  }

  .tag:focus {
    outline: none;
  }

  .tag:hover {
    text-decoration: underline !important;
    cursor: pointer !important;
  }

  .comment-timeStamp {
    font-size: 8px;
    line-height: 9px;
    color: #c4c4c4;
    min-width: fit-content;
  }

  .like-btn {
    cursor: pointer;
    font-weight: bold;
    font-size: 10px;
    color: rgba(255, 255, 255, 0.4);
    min-width: fit-content;
  }

  @media (min-width: 576px) {
    .like-btn:hover {
      color: #fff;
    }
  }
}
/* box-shadow: rgba(136, 153, 166, 0.2) 0px 0px 15px, rgba(136, 153, 166, 0.15) 0px 0px 3px 1px; */
/* background-color: rgb(21, 32, 43); */

/* border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px */
