.curve-editor {
  // width: 1200px;
  // height: 800px;
  width: 100%;
  height: 100%;

  .viewer {
    width: 100%;
    height: 100%;
    background-color: #293643;
  }

  svg {
    width: 100%;
    height: 100%;
    /* background-color: rgb(133, 133, 255); */
    background-color: #293643 !important;

    transform-origin: 1px 1px !important;
    background-size: 100px 100px;
    background-image: linear-gradient(to right, #36424f 2px, transparent 1px),
      linear-gradient(to bottom, #36424f 2px, transparent 1px);
  }

  .segment {
    stroke-width: 4px;
  }

  .segment:hover {
    cursor: pointer;
    stroke: white;
  }

  .handle-line {
    stroke-dasharray: 5, 5;
  }

  .keyframe {
    cursor: pointer;
    stroke-width: 5px;
    fill: rgb(158, 158, 158);
  }

  .keyframe:hover {
    fill: rgb(231, 231, 231);
  }

  .handle {
    cursor: pointer;
    stroke-width: 5px;
    fill: red;
  }

  #path {
    /* fill: none; */
  }
  #path:hover {
    /* stroke: white; */
    /* cursor: pointer; */
  }
}
