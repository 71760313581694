.payment-summary{
    display: flex;
    flex-direction: column;

    font-size: 14px;
}

.payment-summary div {
    margin: 0.5rem 0px;
}

.payment-summary-title{
    display: flex;
    justify-content: space-between;
    
    font-weight: bold;
    font-size: 18px;
}

.payment-summary-credit{
    display: flex;
    justify-content: space-between;

    border-bottom: 1px solid grey;
    padding-bottom: 0.5rem;
    margin-bottom: 0px;
    color: red;
}

.payment-summary-total-amount{
    display: flex;
    justify-content: space-between;

    font-size: 16px;
    font-weight: bold;
}