.item {
  text-align: center;
  // padding: 2vw;
  padding-bottom: 0rem;
  margin-bottom: 1.5vw;
  word-wrap: break-word;
}

.item .item-card {
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 8px;

  display: flex;
  flex-direction: column;

  position: relative;
}

.item .img-container {
  height: 100px;
  width: 100%;

  background-repeat: no-repeat;
  background-position: center;
  background-size: 70%;

  background-size: contain;

  padding: 1.5vw;
  background-origin: content-box;
}

.item:hover {
  cursor: pointer;
}

.item:hover .item-card {
  background-color: rgba(255, 255, 255, 0.3);
}

.item h4 {
  font-size: 1.75vw;
  margin-top: 1.5vw;
}

.carousel-img {
  width: 100%;
  height: 100px;
}

/* ----------------------------------- Love icon */

.item .item-top-row .love-icon {
  position: absolute;
  left: 0.5rem;
  top: 0.5rem;
  z-index: 30;
  font-size: 12px;
}

.item .item-top-row .love-icon path {
  fill: white;
  stroke: white;
  stroke-width: 40px;
  stroke-opacity: 0.5;
}

.item .item-top-row .love-icon.no-fill path {
  fill-opacity: 0;
}

.item .item-top-row .love-icon.no-fill path:hover {
  stroke-width: 60px;
  stroke-opacity: 1;
}

.item .item-top-row .love-icon.fill path {
  fill-opacity: 0.5;
}

.item .item-top-row .love-icon.fill path:hover {
  fill-opacity: 1;
}

/* ----------------------------------- Fullscreen icon */
.fullscreen-icon {
  position: absolute;
  top: 0.2rem;
  right: 0.5rem;
  z-index: 30;

  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
}

/* ----------------------------------- Cloud icon */
.item .item-bottom-row {
  text-align: right;
  // padding-right: 0.3rem;
  // padding-left: 0.3rem;
  // padding-bottom: 0.25rem;
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 30;

  .cloud-icon {
    margin-right: 1vw;
    margin-bottom: 1vw;
  }

  .price-badge {
    position: relative;
    display: none;
    background-color: rgba(0, 0, 0, 0.7);
    width: fit-content;
    margin-left: auto;
    border-top-left-radius: 0.5vw;
    padding: 1vw 3vw;
    font-size: 2vw;
  }
}

.item:hover .price-badge {
  display: block;
}

.item .item-bottom-row img {
  position: relative;
  opacity: 0.5;
  width: 16px;
}

.item .item-bottom-row img:hover {
  opacity: 1;
}

/* ----------------------------------- Dollar icon */
.bottom-left {
  position: absolute;
  left: 0.3rem;
  top: 0.5rem;

  path {
    color: grey !important;
  }
}

/* ----------------------------------- Progress bar */

.item-progress {
  height: 4px;
  width: 80%;
  margin: auto;

  position: relative;
  top: 50%;
}

.item-progress .progress {
  height: 4px !important;
  background-color: rgba(255, 255, 255, 0.6);
}

.item-progress .progress-bar {
  background-color: white !important;
}

/* ----------------------------------- Subcategory */
.subcategory-label {
  font-size: 2vw;
  background-color: #162e42;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  padding: 0.1vw 2vw;
  border-radius: 5px;

  &:hover {
    background-color: #224764;
  }
}
