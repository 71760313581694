.main-container {
  display: flex;
  flex-direction: row;
  overflow: auto;
  height: 100%;
  width: 100%;
}

/* .screen-content-container {
  flex-basis: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: hidden;
} */

/* .screen-content {
  flex-basis: 25%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 4%;
}

.screen-content-title {
  flex: 1;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 1.4vw; 
  margin-bottom: 4%;
  color: rgba(0, 0, 0, 0.4);
}
.screen-content-cards {
  flex: 4;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.screen-content-cards::-webkit-scrollbar {
  display: none;
} */

/* .image1 {
  background: linear-gradient(
      259.86deg,
      rgba(168, 78, 155, 0.5) 25.09%,
      rgba(91, 194, 217, 0.5) 101.98%
    ),
    url("..//assets/images/menu/image\ 51.png");
} */

.input-error {
  outline-color: red;
}
.input-correct {
  outline: none;
}

.icon {
  color: white;
}

/* .add-card-icon {
  font-size: 80px;
  margin-top: 3%;
  color: gray;
  cursor: pointer;
} */



