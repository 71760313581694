.delete-user-modal {
  input {
    width: 100%;
    margin-bottom: 1rem;
    border: 1px solid rgba($color: #000000, $alpha: 0.4);
    padding: 0.5rem 1rem;
  }

  .delete-btn {
    background-color: red;
  }
}
