.purchase-pack-modal2 {
  width: 65vw !important;
  // .ant-modal-content,
  // .ant-modal-header {
  //   // background-color: #11202e;
  //   // color: white !important;
  // }

  .loader {
    margin-top: 4rem;
    margin-bottom: 3rem;
  }

  .item-desc {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 2.5vw;
    .img-container {
      height: 25vw;
      width: 100%;
      // background-color: #414d58;
      background-repeat: no-repeat;
      background-position: center;
      // background-size: 70%;

      background-size: contain;

      padding: 1.5vw;
      background-origin: content-box;

      border-radius: 2vw;

      margin-left: 5vw;
      margin-right: 5vw;
    }

    .price {
      width: fit-content;
      color: black;
      background-color: #dedede;
      padding: 0vw 2vw;
      margin: auto;
      border-radius: 1vw;
      font-weight: 500;
      line-height: 3.5vw;
      text-align: center;
      font-size: 2.25vw;
    }

    .name {
      margin-top: 1vw;
      color: #515151;
      margin-bottom: 2vw;
      text-align: center;
      font-size: 2.25vw;
    }

    .carousel-container {
      // width: 18vw;
      // height: 12vw;

      // background-color: #414d58;
      background-repeat: no-repeat;
      background-position: center;
      margin-left: 4vw;
      margin-right: 4vw;
      margin-bottom: 3vw;

      .carousel-control-prev-icon,
      .carousel-control-next-icon {
        width: 3vw;
      }

      .carousel-inner,
      .carousel-item,
      .carousel-img {
        width: 27vw;
        height: 15vw;
        height: 25vw;
        width: 100%;
      }
    }
  }

  // .summary {
  //   margin: 3vw 6vw;
  //   p {
  //     font-size: 2vw;
  //     text-align: center;
  //     font-style: italic;
  //   }
  // }

  .footer {
    text-align: center;

    button {
      outline: none;
      border: none;
      border-radius: 1vw;
      padding: 0.5vw 3vw;
      font-size: 2.5vw;
      color: white;
      font-weight: bold;
    }

    .cancel {
      background-color: #c4c4c4;
      margin-right: 3vw;
    }

    .submit {
      background-color: #1b76e8;
    }
  }
}
