.exports {
  background-color: #0a141d;
  height: 100%;
  color: white;
  overflow: auto;
  display: flex;
  flex-direction: column;

  .save-btn {
    position: absolute;
    top: 7px;
    right: 10px;

    width: 26px;
    height: 26px;

    text-align: center;

    // padding: 0px 5px;
    border-radius: 50%;
    background-color: #254661;

    cursor: pointer;

    &:hover {
      background-color: #427cac;
    }
  }

  .exports-header {
    h1 {
      padding-left: 6vw;
      padding-top: 6vw;
      margin-bottom: 0%;
      font-weight: 700;
      font-size: 6vw;
      color: white;
    }

    a {
      margin-left: 5vw;
      font-size: 4vw;
      font-weight: 300;
      color: white;

      &:hover {
        color: white;
      }
    }

    .copy-link {
      display: flex;
      align-items: center;
      margin: 1rem;
      background-color: #ffffff;
      font-size: 12px;

      img {
        padding: 0.75rem;
        // height: 100%;
        height: 40px;
        width: 50px;
        margin-right: 1rem;
        border-right: 1px solid rgba($color: #000000, $alpha: 0.2);
      }

      .url-link {
        padding-right: 1rem;
        // width: 100%;
        // background-color: white;
        color: black;

        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .copy-btn {
        height: 40px;
        padding: 0.75rem 1.25rem;
        color: white;
        margin-left: auto;
        background: #0094ff;
        // border-radius: 4px;
        border: none;
        outline: none;
        &.copied {
          background: #26c318;
        }
      }
    }
  }

  .complete-sign {
    margin-top: 1rem;
    text-align: center;
    margin-bottom: -1rem;
    h1,
    h2 {
      font-size: 12px;
      color: white;
    }
    img {
      width: 80px;
    }
  }

  .exports-body {
    margin-top: 4vw;
    padding: 4vw;
    flex-grow: 1;
    height: 100%;
    overflow: auto;
  }

  .export {
    border-top: 1px solid rgba($color: #ffffff, $alpha: 0.2);
    border-bottom: 1px solid rgba($color: #ffffff, $alpha: 0.2);
    padding: 5vw 2vw;
    cursor: pointer;

    &.completed {
      border-top: 1px solid rgba($color: #ffffff, $alpha: 0);
    }

    & {
      color: rgba($color: #ffffff, $alpha: 0.5);
    }

    &.checked {
      color: rgba($color: #ffffff, $alpha: 0.8);
    }

    &:hover {
      color: rgba($color: #ffffff, $alpha: 1);
    }

    .export-header {
      display: flex;
      align-items: center;
      width: 100%;
    }

    .name {
      margin-left: 4vw;
      font-weight: 700;
      font-size: 4vw;
    }

    .upload-progress {
      font-size: 3vw;
      color: white;
      font-style: italic;
    }

    .retry {
      margin-left: 3vw;
      background-color: grey;
      border: none;
      border-radius: 4px;
    }

    .link-icon {
      margin-left: auto;
    }

    .drop-arrow {
      margin-left: auto;
    }

    &.completed .drop-arrow {
      margin-left: 1vw;
    }

    &.collapsed .export-body {
      display: none;
    }

    .export-body {
      display: flex;
      padding-left: 9vw;
      margin-top: 2vw;

      p {
        font-size: 3vw;
        margin-bottom: 0%;
      }

      span {
        color: rgba($color: #ffffff, $alpha: 0.6);
      }
    }

    .export-body div:nth-child(2) {
      margin-left: 15vw;
    }
  }

  .exports-footer {
    display: flex;
    align-items: center;
    padding: 4vw;
    padding-top: 0px;
    font-size: 3.5vw;
    display: flex;
    flex-direction: column;
    align-items: baseline;

    & > div {
      width: 100%;
      display: flex;
      align-items: center;
      margin-top: 3vw;
    }

    .custom-checkbox {
      margin-left: auto;
      font-size: 3vw;
    }

    select {
      background-color: #0a141d;
      padding: 1vw 2.5vw;
      margin-right: 3vw;
      color: #808080;
      border: 1px solid #808080;
      border-radius: 5px;
      cursor: pointer;
    }

    .export-btn {
      margin-left: auto;
      button {
        background-color: #1a76e8;
        border-radius: 4px;
        outline: none;
        border: none;
        padding: 2vw 3vw;
        font-weight: 700;
      }

      button:disabled {
        background-color: grey;
        cursor: not-allowed;
      }
    }

    &.completed .export-btn button {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
