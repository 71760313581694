#comments-main {
  width: 100vw;
  height: 100vh;

  background: #000000 !important;
  color: white !important;

  padding-left: 1rem;
  padding-right: 1rem;
}
