.login-container .row {
  margin: 0px !important;
}

.full-width {
  width: 100%;
}
.full-height {
  height: 100%;
}

.expand {
  width: 100%;
  height: 100%;
}

.login-container {
  background-color: white;
  height: 100vh;
  padding-right: 0px !important;
  padding-left: 0px !important;
  margin: 0px !important;
}

.login-container .flex-container {
  height: 100vh;
  margin: 0px !important;
  display: flex;
  flex-direction: column;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  overflow: auto;
}

.flex-container .logo {
  margin-top: 75%;
  text-align: center;
}

/* Wearables, small mobile devices (less than 320px)*/
@media (max-width: 319px) {
  .login-container .logo img {
    width: 100%;
  }
}

/* Mobile devices (portrait phones, 320px and up) */
@media (min-width: 320px) {
  .login-container .logo img {
    width: 100%;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
  .login-container .logo img {
    width: 60%;
  }

  .login-container .logo {
    margin-top: 70%;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .login-container .logo {
    margin-top: 60%;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
