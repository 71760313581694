html {
  max-width: 100%;
}

body {
  margin: 0;
  width: 100vw;
  max-width: 100%;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  -ms-user-select: none;
  user-select: none;
}

code {
  font-family: 'Roboto', sans-serif;
}

.scroll-bar::-webkit-scrollbar {
  width: 8px;
}

.scroll-bar::-webkit-scrollbar-track {
  border-radius: 10px;
}

.scroll-bar::-webkit-scrollbar-thumb {
  border-radius: 10px;

  background-color: rgba(255, 251, 251, 0.05);
  -webkit-box-shadow: inset 0 0 6px rgba(90, 90, 90, 0.7);
  box-shadow: inset 0 0 6px rgba(90, 90, 90, 0.7);
}

.ant-picker-time-panel-column::after {
  height: 0px;
}

.modal {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
