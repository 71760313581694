.blueprint .info * {
  /* color: white; */
  /* word-break: break-word; */
  * {
    color: white;
  }

  .env-settings {
    font-size: 10px;

    .title {
      font-size: 18px;
      font-weight: bold;
      margin-top: 3rem;
      margin-bottom: 3rem;
    }

    .detail {
      margin-bottom: 1rem;
      img {
        width: 14px;
      }
      span {
        display: inline-block;
        margin-left: 1rem;
        width: 100px;
      }
    }
  }
}

.card-row {
  margin-top: 1.25rem;
}

.cam-equipment {
  display: flex;
  flex-wrap: wrap;
}

.cam-equipment div {
  width: fit-content;
  font-size: 10px;
  border-radius: 5px;
  padding: 0.25rem 0.5rem;
  margin-right: 0.25rem;
  background-color: rgba(255, 255, 255, 0.2);
}

.info-empty {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.blueprint .info .info-empty * {
  color: rgba(255, 255, 255, 0.5) !important;
  font-size: 1rem;
}

.blueprint .info .card-title {
  color: white;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
}

.blueprint .info .title-icon {
  margin-right: 0.75rem;
}

.blueprint-info-player {
  margin-top: 1rem;
  margin-bottom: 2rem;
  /* margin-right: 2rem; */
}

.blueprint .info .info-key {
  color: rgba(255, 255, 255, 0.4);
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
}

.blueprint .info .info-value {
  color: white;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 12px;

  .color-pad {
    width: 20px;
    height: 20px;

    border-radius: 50%;
  }
}

.latest-comment {
  margin-top: 2rem;
  /* padding-right: 2rem; */
}

.latest-comment .title {
  color: white;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
}

.latest-comment .title a {
  color: rgba(0, 148, 255, 0.4) !important;

  /* margin-right: 2rem; */

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
}

.blueprint .info .latest-comment .title a:hover {
  color: rgba(0, 148, 255, 1) !important;
}

.blueprint .info .latest-comment .comment .body {
  background-color: inherit;
  border: 1px solid rgba(255, 255, 255, 0.4);
}

.blueprint .info .latest-comment .comment-timeStamp {
  color: #505050;
}

.blueprint .info .latest-comment .like-btn {
  color: #6b7277;
}

.blueprint .info .card-row-key {
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;

  color: rgba(255, 255, 255, 0.4);
}

.blueprint .info .card-row-value {
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
}

.blueprint .info .ant-tabs-top > .ant-tabs-nav::before {
  border: none;
}

.blueprint .info .comments {
  overflow-y: auto;
  overflow-x: hidden;
}

.blueprint .info .comments::-webkit-scrollbar {
  width: 12px;
}

.blueprint .info .comments::-webkit-scrollbar-track {
  border-radius: 10px;
}

.blueprint .info .comments::-webkit-scrollbar-thumb {
  border-radius: 10px;

  background-color: rgba(255, 251, 251, 0.05);
  -webkit-box-shadow: inset 0 0 6px rgba(90, 90, 90, 0.7);
}

.object-thumbnail {
  max-height: 150px;
  height: 100%;
  /* padding: 0px 2rem; */
}

.object-thumbnail img {
  max-height: 150px;
  height: 100%;
}
