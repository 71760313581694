/* .large-view #items-grid .item .item-top-row {
  height: 30px;
  padding-left: 0.4rem;
  padding-top: 0.4rem;
}

.large-view #items-grid .item .item-top-row .love-icon {
  font-size: 14px;
} */

.large-view #items-grid {
  padding: 3vw;
  grid-template-columns: 28vw 28vw 28vw;
  // grid-auto-rows: 40vw;
  column-gap: 4vw;
  grid-auto-rows: auto;
  .item {
    // height: 35vw;
    height: auto;
    margin-bottom: 4vw;

    .img-container {
      height: 28vw;
    }
  }
}

.large-view #items-grid.has-sidebar {
  padding: 2vw;
  grid-template-columns: 32vw 32vw;
  column-gap: 4vw;
  // grid-auto-rows: 43vw;
  grid-auto-rows: auto;

  .item {
    // height: 42vw;
    height: auto;
    margin-bottom: 4vw;

    .img-container {
      height: 32vw;
    }
  }
}

.large-view.panorama {
  #items-grid {
    grid-template-columns: 44vw 44vw;
    grid-auto-rows: 30vw;
  }
  .carousel-img {
    object-fit: cover;
    border-radius: 5px;
  }

  .item:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  .fullscreen-icon {
    display: none;
  }
}
// @media (max-width: 319px) {
//   .large-view #items-grid {
//     flex: 1;
//   }
//   .large-view #items-grid .item {
//     width: 100%;
//   }
//   .large-view #items-grid .img-container {
//     height: calc(100vw - 2rem);
//   }

//   .large-view #items-grid.has-sidebar .item {
//     width: 100%;
//   }
//   .large-view #items-grid.has-sidebar .img-container {
//     height: calc(100vw - 150px - 2rem);
//   }
// }

// @media (min-width: 320px) {
//   .large-view #items-grid {
//     flex: 1;
//   }
//   .large-view #items-grid .item {
//     width: 33.333%;
//   }
//   .large-view #items-grid .img-container {
//     height: calc((100vw - 6rem) / 3);
//   }

//   .large-view #items-grid.has-sidebar .item {
//     width: 50%;
//   }
//   .large-view #items-grid.has-sidebar .img-container {
//     height: calc((100vw - 150px - 4rem) / 2);
//   }
// }

// @media (min-width: 400px) {
//   .large-view #items-grid {
//     flex: 1.5;
//   }
// }

// @media (min-width: 576px) {
//   .large-view #items-grid {
//     flex: 3;
//   }
//   .large-view #items-grid .item {
//     width: 33.33%;
//   }
//   .large-view #items-grid .img-container {
//     height: calc((100vw - 6rem) / 3);
//   }

//   .large-view #items-grid.has-sidebar .item {
//     width: 50%;
//   }
//   .large-view #items-grid.has-sidebar .img-container {
//     height: calc((100vw - 200px - 4rem) / 2);
//   }
// }

// @media (min-width: 768px) {
//   .large-view #items-grid {
//     flex: 4;
//   }
//   .large-view #items-grid .item {
//     width: 25%;
//   }
//   .large-view #items-grid .img-container {
//     height: calc((100vw - 8rem) / 4);
//   }

//   .large-view #items-grid.has-sidebar .item {
//     width: 33.33%;
//   }
//   .large-view #items-grid.has-sidebar .img-container {
//     height: calc((100vw - 200px - 6rem) / 3);
//   }
// }

// @media (min-width: 992px) {
//   .large-view #items-grid {
//     flex: 5;
//   }
//   .large-view #items-grid .item {
//     width: 16.666%;
//   }
//   .large-view #items-grid .img-container {
//     height: calc((100vw - 12rem) / 6);
//   }

//   .large-view #items-grid.has-sidebar .item {
//     width: 25%;
//   }
//   .large-view #items-grid.has-sidebar .img-container {
//     height: calc((100vw - 200px - 8rem) / 4);
//   }
// }

// @media (min-width: 1200px) {
//   .large-view #items-grid {
//     flex: 6;
//   }
//   .large-view #items-grid .item {
//     width: 14.285%;
//   }
//   .large-view #items-grid .img-container {
//     height: calc((100vw - 14rem) / 7);
//   }

//   .large-view #items-grid.has-sidebar .item {
//     width: 20%;
//   }
//   .large-view #items-grid.has-sidebar .img-container {
//     height: calc((100vw - 200px - 10rem) / 5);
//   }
// }

// @media (min-width: 1600px) {
//   .large-view #items-grid {
//     flex: 6;
//   }
//   .large-view #items-grid .item {
//     width: 12.5%;
//   }
//   .large-view #items-grid .img-container {
//     height: calc((100vw - 16rem) / 8);
//   }

//   .large-view #items-grid.has-sidebar .item {
//     width: 16.6667%;
//   }
//   .large-view #items-grid.has-sidebar .img-container {
//     height: calc((100vw - 200px - 12rem) / 6);
//   }
// }
