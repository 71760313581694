.Paybutton {
  white-space: nowrap;
  border: 0;
  outline: 0;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
  color: #fff;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  background-color: #007bff;
  text-decoration: none;
  /* -webkit-transition: all 150ms ease; */
  /* transition: all 150ms ease; */
  margin-top: 10px;
} 

.Paybutton:disabled,
.Paybutton[disabled]{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

 .Paybutton:hover:not([disabled]) {
  color: #fff;
  cursor: pointer;
  background-color: #40a9ff;
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, .10), 0 3px 6px rgba(0, 0, 0, .08);
}

.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border-radius: 4px;
  background: white;
}

.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  /* -webkit-transition: all 150ms ease; */
  /* transition: all 150ms ease; */
}

.ErrorMessage svg {
  margin-right: 10px;
}

.Result {
  margin-top: 50px;
  text-align: center;
  animation: fade 200ms ease-out;
}

.ResultTitle {
  color: #fff;
  font-weight: 500;
  margin-bottom: 8px;
  font-size: 17px;
  text-align: center;
}

.ResultMessage {
  color: green;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 25px;
  line-height: 1.6em;
  text-align: center;
}

.ResetButton {
  border: 0;
  cursor: pointer;
}
