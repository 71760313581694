.like-container {
  display: flex;
  height: 24px;
  background: #252525;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 40px;
  width: 45px;
  align-items: center;
  cursor: pointer;
  position: absolute;
  right: 0px;
  bottom: -12px;
}

.like-background {
  display: flex;
  height: 20px;
  width: 20px;
  margin-left: 2px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 10px;
  background-color: rgb(59, 89, 152);
}

.like-background svg {
  position: absolute;
}

.like-hand {
  color: white;
}
