.blueprint-container {
  background-color: #132434;
  width: 100%;
  height: 100%;

  .ruler {
    border-left: 1px solid rgba(255, 255, 255, 0.2);

    &.horizontal canvas {
      border-left: 1px solid rgba(255, 255, 255, 0.2);
    }

    &.vertical canvas {
      border-top: 1px solid rgba(255, 255, 255, 0.2);
    }
  }

  .viewer {
    background-color: #132434;
    height: 100%;
    width: 100%;
  }
}

.guides {
  // position: absolute !important;
  // top: 0;
  // left: 0;
  // transform: translateZ(1px);
}

.blue-body {
  display: flex;
  width: 100%;
  height: 100%;

  .infinite-container {
    width: 100%;
    height: calc(100% - 30px);

    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }

  svg {
    background-color: #11212e !important;
    // width: 100% !important;
    // TODO
    // background-image: url('/blueprints/grid.svg');
    // background-position: right 61px bottom 61px;
    transform-origin: 1px 1px !important;
    background-size: 50px 50px;
    background-image: linear-gradient(
        to right,
        rgba(128, 128, 128, 0.2) 2px,
        transparent 1px
      ),
      linear-gradient(to bottom, rgba(128, 128, 128, 0.2) 2px, transparent 1px);
    // width: 5000px;
    // height: 5000px;

    &.x200 {
      background-size: 200px 200px;
    }

    &.x100 {
      background-size: 100px 100px;
    }

    &.x50 {
      background-size: 50px 50px;
    }

    &.x20 {
      background-size: 20px 20px;
    }

    &.x10 {
      transform-origin: 0.25px 0.25px !important;
      background-size: 10px 10px;
      background-image: linear-gradient(
          to right,
          rgba(128, 128, 128, 0.2) 0.5px,
          transparent 1px
        ),
        linear-gradient(
          to bottom,
          rgba(128, 128, 128, 0.2) 0.5px,
          transparent 1px
        );
    }
  }

  svg * {
    cursor: pointer;
    background-color: #11212e !important;
  }

  .svg-text {
    color: white;
  }
}

.react-transform-component {
  width: 100% !important;
  height: 100% !important;
}

.react-transform-component:hover {
  cursor: grab;
}

.react-transform-component:active {
  cursor: grabbing;
}

.distance:hover {
  cursor: pointer;
}
