.home {
  height: 100vh;
  display: flex;
  flex-wrap: nowrap;

  .close-btn,
  .paint-btn {
    position: absolute;
    // top: 10px;
    // right: 10px;

    width: 1vw;
    height: 1vw;

    z-index: 1000;

    cursor: pointer;

    position: absolute;
    top: 0.75vw;
    right: 2vw;
    fill: rgb(255, 255, 255);
    width: 1vw;
    height: 1vw;

    &:hover svg {
      fill: rgb(255, 122, 122);
      // fill: red;
    }
  }

  .paint-btn {
    right: 4vw;
  }
}
