.team-members-settings {
  hr {
    margin: 1vw 0vw;
  }
  .team-member {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1vw;
    font-size: 1vw;
    .user {
      width: 35%;
      .tag {
        font-size: 0.8vw;
        padding: 0.2vw;
        background-color: rgb(0, 153, 255);
        color: white;
        margin-right: 0.5vw;
      }

      .email {
        width: 100%;
        word-wrap: break-word;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .role {
      // width: 12%;
      margin-left: 1vw;
      flex-grow: 1;
    }

    // .make-admin,
    // .make-billing-admin {
    //   padding: 0.25vw;
    //   margin-left: 1vw;
    //   margin-right: 1vw;
    //   border: 1px solid rgba(0, 0, 0, 0.2);
    //   cursor: pointer;
    // }

    .edit-user {
      margin-right: 2vw;
      color: #00978d;
    }

    .remove {
      color: #c71c1c;
    }

    .edit-user,
    .remove {
      cursor: pointer;
    }
  }

  .team-members-header {
    font-weight: 500;
    font-size: 1.25vw;

    margin-right: 1.5vw;
  }

  .licenses-length {
    display: flex;
    flex-direction: column;

    font-weight: 300;
    line-height: 23px;
    color: rgba(0, 0, 0, 0.8);

    span {
      font-size: 0.8vw;
      font-weight: bold;
    }
  }

  .new-member-btn {
    cursor: pointer;
  }

  .new-member-btn:hover {
    text-decoration: underline;
  }

  .team-members {
    color: black !important;
    /* padding: 0 1rem; */
  }

  .team-members .ant-list-item {
    border-bottom: none;
    font-size: 1vw;
  }

  .team-member-email {
    font-weight: 300;
    color: rgba(0, 0, 0, 0.8);
  }

  .team-member-role {
    font-size: 1vw;
    margin-left: 1rem;
  }
}
