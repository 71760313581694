.subscription-expired-modal {
    width: 368px !important;
    display: flex;
    .info{
        font-family: Roboto;
        font: 2vw;
        font-weight: 500;
        padding-left: 2vw;
        padding-top: 1vw;
    }
    .open-btn {
        margin-top: 2rem;
        width: 100%;
        text-align: center;
        button {
          border-radius: 10px;
          padding: 0.5rem 1.5rem;
          background-color: #0075e1;
          color: white;
          border: none;
          outline: none;
          
        }
    
        button:hover {
          background-color: #2395ff;
        }
    
        button:active {
          background-color: #0063c0;
        }
    
        button:disabled {
          background-color: #a7a7a7;
          cursor: not-allowed;
        }
    }

    .switch-drive {
        text-align: right;
        cursor: pointer;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 0.7vw;
        line-height: 0.7vw;
        /* width: 60%; */
        color: #2395ff;
        padding-top: 1vw;
      }
}