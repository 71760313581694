#nav-container {
  display: grid;
  /* flex-wrap: wrap; */
  /* align-items: baseline; */
  grid-template-columns: repeat(4, 15vw);
  column-gap: 4.8vw;
  grid-auto-rows: 26vw;
  margin-left: auto;
  margin-right: auto;

  .cameras {
    background-color: #26d474;
  }

  .lights {
    background-color: #ffd417;
  }

  .characters {
    background-color: #ff6f1c;
  }

  .props {
    background-color: #a84e9b;
  }

  .film-sets {
    background-color: #eb2d40;
  }

  .images {
    background-color: #1a76e8;
  }

  .my-assets {
    background-color: #2dbdeb;
  }

  .team-library {
    background-color: #8858ea;
  }
}

.nav {
  position: relative;
  margin-top: 7vw;
  margin-bottom: 0.5vw;
  padding: 0rem;
  // height: 100%;
  height: 15vw;

  .nav-icon {
    display: flex;
    width: 100%;
    height: 100%;

    border: 0.65vw solid transparent;
    border-radius: 2vw;

    img {
      margin: auto;
    }
  }

  .badge {
    position: absolute;
    right: -1.5vw;
    top: -1.5vw;
    // width: fit-content;
    width: 5vw;
    height: 5vw;
    font-size: 2.5vw;
    padding: 1vw 1vw;
    background-color: #ff636b;
    border-radius: 50%;
  }

  &:hover .nav-icon {
    border: 0.65vw solid white;
  }
}

.nav:hover {
  cursor: pointer;
}

.nav * {
  margin: auto;
  text-align: center;
}

.nav img {
  border-color: transparent !important;
  margin-bottom: 1vw;
  // width: 100%;
  border: 0.5vw solid;
}

.nav h4 {
  margin-top: 2vw;
  word-wrap: normal;
  // word-break: break-all;
  font-weight: normal;
  font-size: 2.3vw;
}

/* @media (max-width: 319px) {
  .nav img {
    border: 2px solid;
  }
  .nav:hover img {
    border-radius: 10px;
  }
}

@media (min-width: 320px) {
  .nav img {
    border: 2px solid;
  }
  .nav:hover img {
    border-radius: 14px;
  }
}

@media (min-width: 576px) {
  .nav img {
    border: 4px solid;
  }
  .nav:hover img {
    border-radius: 24px;
  }
}

@media (min-width: 768px) {
  .nav:hover img {
    border-radius: 34px;
  }
} */
