.slider-wrapper {
  color: black;
  position: relative;
  /* padding: 0px 30px; */
  padding: 0px 60px 0px 59px;
  width: 100%;
  bottom: 90px;
  display: flex;
  align-items: center;
}

.slider-wrapper * {
  color: white !important;
}

.slider-wrapper .anticon {
  position: relative;
  bottom: 6px;
  left: -16px !important;
  width: 16px;
  height: 16px;
  line-height: 1;
  font-size: 1.5rem;
}

.slider-wrapper .slider-time {
  /* position: absolute; */
  top: 8px;
  margin-left: 1rem;
  /* right: 20px; */
  width: 16px;
  height: 16px;
  line-height: 1;
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.6) !important;
}

.ant-slider-track {
  background-color: #ffffff !important;
}

.ant-slider:hover .ant-slider-track {
  background-color: #ffffff !important;
}

.slider-wrapper .ant-slider-rail {
  background-color: #8a8a8a !important;
}

.slider-wrapper .ant-slider-rail:hover,
.ant-slider .slider:hover,
.ant-slider:hover .ant-slider-rail {
  background-color: #8a8a8a !important;
}

.ant-slider-handle {
  border-color: #ffffff !important;
  background-color: #ffffff !important;
}

.slider-wrapper .slider-wrapper-active {
  color: white;
}

.slider-wrapper .anticon:first-child {
  left: 0;
}

.slider-wrapper .anticon:last-child {
  right: 0;
}
