.project-sub-modal {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba($color: #000000, $alpha: 0.5);
  z-index: 100;

  .body {
    width: 50%;
    position: relative;
    top: 30%;
    left: 50%;

    transform: translate(-50%, 0%);

    padding: 1rem;

    background-color: white;
  }
}
