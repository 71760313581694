.scene-settings {
  & {
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    overflow-y: auto;
    color: white;
    background-color: #11202e;

    // text-align: center;
    padding: 10vw 7vw;
  }

  & > .scene-title {
    font-size: 5vw;
  }

  .clickable:hover {
    cursor: pointer;
  }

  &::-webkit-scrollbar {
    width: 4vw;
  }

  &::-webkit-scrollbar-track {
    border-radius: 4vw;
    background-color: rgba(255, 251, 251, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4vw;

    background-color: rgba(255, 251, 251, 0.2);
    -webkit-box-shadow: inset 0 0 6px rgba(90, 90, 90, 0.7);
    box-shadow: inset 0 0 6px rgba(90, 90, 90, 0.7);
  }
}
