.not-available {
  position: relative;
  width: 78vw;
  height: 100vh;
  &.original {
    background-color: #11202e !important;
  }
  &.black {
    background-color: black !important;
  }

  h1 {
    position: absolute;
    font-weight: bold;
    color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    font-size: 1.35vw;
  }
}
