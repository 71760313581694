.bug-report {
  width: 100vw;
  height: 100vh;
  background-color: #11202e;

  form {
    padding: 1rem;

    label {
      color: white;
    }

    .ant-btn {
      margin-top: 0.5rem;
      margin-right: 0.5rem;
    }
  }
}
