.buy-team-modal {
  .ant-modal-close {
    // display: none;
  }

  .price {
    text-align: center;
    h1 {
      color: #ffffff;
      margin: auto;
      font-size: 18px;
      font-weight: bold;
      width: fit-content;
      border: 1px solid rgba($color: #ffffff, $alpha: 0.3);
      padding: 1.5rem 0.5rem;
      border-radius: 50%;
    }
  }

  .StripeElement {
    margin: 0;
  }

  .ant-row.submit {
    margin: 0;
    margin-top: 1rem;
  }

  button[type='submit'] {
    width: 100%;
    height: 100%;
    background-color: #4caf50;
    color: white;
    // padding: 14px 20px;
    // margin: 8px 0;
    padding-top: 1rem;
    padding-bottom: 1rem !important;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    font-weight: bold;
    font-size: 18px;
  }

  button[type='submit']:hover {
    background-color: #45a049;
  }
}
